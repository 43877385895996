import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

import axios from 'axios'
Vue.prototype.axios = axios;

Vue.directive('removeAriaHidden', {
    bind(el, binding) {
        let ariaEls = el.querySelectorAll('.el-radio__original');
        ariaEls.forEach((item) => {
            item.removeAttribute('aria-hidden');
        });
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
