<template>
    <div class="bottom_page">
        <!-- Unnamed (矩形) -->
        <div id="u95" class="ax_default _三级标题">
            <div id="u95_div" class=""></div>
            <div id="u95_text" class="text ">
                <p style="margin-bottom:10px"><span>北京盈科千信科技有限公司</span></p>
                <p><span>地址：北京市海淀区中关村南大街48号</span></p>
                <!-- <p><span><br></span></p> -->
            </div>
        </div>

        <!-- Unnamed (矩形) -->
        <div id="u96" class="ax_default _三级标题">
            <div id="u96_div" class=""></div>
            <div id="u96_text" class="text ">
                <p style="margin-bottom:10px"><span style="font-family:'PingFang SC 常规', 'PingFang SC';">邮箱：</span><span
                        style="font-family:'Arial Normal', 'Arial';">product@increscence.net</span></p>
                <p>
                    <span style="font-family:'Arial Normal', 'Arial';">Copyright © {{ year }}</span>
                    <span class="pic">
                        <img width="25px" src="@/assets/img/copy_n.09f5554.png" alt="">
                    </span>
                    <span style="font-family:'PingFang SC 常规', 'PingFang SC';">京</span>
                    <span style="font-family:'Arial Normal', 'Arial';">ICP</span>
                    <span style="font-family:'PingFang SC 常规', 'PingFang SC';">备</span>
                    <span style="font-family:'Arial Normal', 'Arial';">15054044</span>
                    <span style="font-family:'PingFang SC 常规', 'PingFang SC';">号</span>
                    <span style="font-family:'Arial Normal', 'Arial';">-1</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            year: ''
        }
    },
    mounted() {
        const date = new Date()
        this.year = date.getFullYear()
    },
    methods: {

    }


}
</script>

<style lang="scss" scoped>
.bottom_page {
    width: 100%;
    background-color: #0A214D;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 15px 0;
    box-sizing: border-box;
    font-size: 14px;

    #u95 {
        border-right: 2px solid #fff;
        padding-right: 40px;
        margin-right: 40px;
        height: 100%;
        // background-color: rgb(226, 150, 51);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #u96 {
        .pic {
            margin: 0 5px;
            vertical-align: middle;
        }
    }
}
</style>