<template>
    <div>
        <div class="main-page">
            <div class="main_content">
                <div class="fun_area">
                    <div>
                        <!-- <button>AI优化</button> -->
                        <button @click="randomText">示例</button>
                    </div>
                    <span @click="goMine">生成记录</span>
                </div>
                <div v-if="showType === 'list'">
                    <div v-if="playVideo" class="video_box">
                        <video :src="videoUrl" autoplay :controls="true" style="width: 100%;"></video>
                    </div>
                    <div v-else>
                        <ul class="list_box">
                            <li v-for="item in videoList" :key="item.id">
                                <video :src="item.video_url" style="width: 100%;"></video>
                                <div class="mask_box" :key="key">
                                {{ item.key }}
                                    <el-progress v-if="item.status === 4" type="circle" :percentage="item.progress"
                                        style="color: #ffffff;width:45px;height:45px;font-size: 14px"></el-progress>
                                    <div v-if="item.status === 1" class="start_play" @click="goDetail(item.video_url, item.create_time)">
                                        <i class="el-icon-caret-right"></i>
                                    </div>
                                    <div v-if="item.status === 2 || item.status === 3">
                                        生成失败
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <div v-else>
                    <div class="text_area">
                        <textarea v-model="textcontent" name="" id="" cols="30" rows="10" maxlength="5000"></textarea>
                        <div style="text-align: end;margin-top:5px">{{ textLength }} / 5000</div>
                    </div>
                    <div class="top_tit" style="margin-top:20px;">
                        <div class="set_btn" @click="showSet = !showSet">
                            <img src="@/assets/设置.png" alt="">
                            设置
                        </div>
                        <div class="summarize_btn" @click="madeVideo">
                            生成视频
                        </div>
                    </div>
                    <div class="set_area" v-if="showSet">
                        <ul>
                            <div>
                                <div style="margin-bottom:15px">视频</div>
                                <li style="align-items: flex-start;">
                                    <span class="set_title">画面比例：</span>
                                    <div>
                                        <div :style="{ 'border-color': proportion === '16:9' ? '#156AD4' : '' }"
                                        class="btn_size" style="margin-right: 10px;" @click="proportion = '16:9'">
                                            <img src="../assets/横.png" alt="" height="12px">
                                            16:9
                                        </div>
                                        <div :style="{ 'border-color': proportion === '9:16' ? '#156AD4' : '' }"
                                            class="btn_size" @click="proportion = '9:16'">
                                            <img src="../assets/竖.png" width="12px">
                                            9:16
                                        </div>
                                    </div>
                                </li>
                            </div>
                            <div>
                                <div style="margin-bottom:15px">音频</div>
                                <div>
                                    <li>
                                        <span style="width:75px" class="set_title">配音声音：</span>
                                        <el-select class="sel_box" v-model="voiceValue" placeholder="请选择" size="mini">
                                            <el-option v-for="(item, index) in voiceOptions" :key="index" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </li>
                                    <li>
                                        <span style="width:75px" class="set_title">背景音：</span>
                                        <el-select class="sel_box" v-model="bgmValue" placeholder="请选择" size="mini">
                                            <el-option v-for="(item, index) in bgmOptions" :key="index" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </li>
                                </div>
                            </div>
                            <div>
                                <div style="margin-bottom:15px">
                                    <el-checkbox v-model="checked"></el-checkbox>
                                    字幕
                                </div>
                                <div>
                                    <li class="same_box">
                                        <span class="set_title">字体：</span>
                                        <el-select :disabled="!checked" class="sel_box" v-model="fontValue" placeholder="请选择" size="mini">
                                            <el-option v-for="(item, index) in fontOptions" :key="index" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-select :disabled="!checked" v-model="sizeValue" placeholder="请选择" style="margin: 0 20px;width:80px" size="mini">
                                            <el-option v-for="(item, index) in sizeOptions" :key="index" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-color-picker :disabled="!checked" v-model="fontColor"></el-color-picker>
                                    </li>
                                    <li class="same_box">
                                        <span class="set_title">边框：</span>
                                        <el-color-picker :disabled="!checked" v-model="color1" style="margin-right: 20px"></el-color-picker>
                                        <el-select :disabled="!checked" v-model="borderValue" placeholder="请选择" size="mini">
                                            <el-option v-for="(item, index) in borderOptions" :key="index" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </li>
                                    <li class="same_box">
                                        <span class="set_title">位置：</span>
                                        <el-select :disabled="!checked" v-model="positionValue" placeholder="请选择" size="mini">
                                            <el-option v-for="(item, index) in positionOptions" :key="index" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                   
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { madeVideoApi, getProcessApi, getVideoListApi } from '../api/textTo'
export default {
    data() {
        return {
            checked: true,
            playVideo: false,
            showType: 'new',
            proportion: '16:9',
            textcontent: '',
            exampleText:[
                '生命，这个宇宙中最神奇的奇迹，它的意义究竟是什么？\n或许，生命的意义，在于那些温暖人心的瞬间，连接彼此的纽带，传递爱与希望。\n它也许在于，为这个世界留下我们的痕迹，无论是一棵树，一篇文章，还是一个微笑。\n生命的意义，在于探索、连接、贡献。最终，它在于我们如何定义它。在这短暂而宝贵的旅程中，让我们用爱和勇气，书写自己的故事。',
                '在这个快速变化的世界中，理解个人理财的重要性，对我们每个人来说都至关重要。\n预算，是个人理财的基石。它帮助我们控制开支，确保我们的收入能满足我们的需求和目标。\n储蓄，是为未来的不确定性建立安全网。无论是应急基金还是长期目标，储蓄都是实现财务自由的关键步骤。\n投资，让我们的钱为我们工作。通过明智的投资选择，我们可以让我们的资产随着时间增长。\n信用卡管理，是维护良好信用记录和避免高利债务的关键。合理使用信用卡可以帮我们建立信用，享受更多财务自由。\n通过有效的预算、储蓄、投资和信用卡管理，我们不仅能应对今天的挑战，还能为未来打下坚实的基础。让我们从现在开始，走向财务自由之路。',
                '生命，这个宇宙中最神奇的奇迹，它的意义究竟是什么？\n或许，生命的意义，在于那些温暖人心的瞬间，连接彼此的纽带，传递爱与希望。\n它也许在于，为这个世界留下我们的痕迹，无论是一棵树，一篇文章，还是一个微笑。\n生命的意义，在于探索、连接、贡献。最终，它在于我们如何定义它。在这短暂而宝贵的旅程中，让我们用爱和勇气，书写自己的故事。',
                '在这个快节奏的生活里，我们常常忘了停下脚步，寻找那些让心灵微笑的瞬间。\n从今天开始，给自己的生活增添一抹色彩。尝试一项新的爱好，无论是画画、烹饪，还是学习一门新乐器。\n找回与家人朋友共度的欢乐时光。在自然中放松身心，让爱和笑声充满你的每一天。\n偶尔，给自己一点独处的时间。在星空下散步，或是静静地阅读一本书，找到属于自己的平静。\n生活的乐趣无处不在，只要你愿意去发现。今天，就从一个小小的改变开始，让我们的生活更加丰富多彩。',
                '在这个充满挑战的时代，保持心理健康变得比以往任何时候都重要。\n冥想，是连接内心深处的桥梁，它帮助我们减少焦虑，增强自我意识，让心灵得到真正的安宁。\n通过写日记，我们能够更好地理解自己的情绪与想法，这是一种释放内心压力、提升自我认知的有效方法。\n阅读，不仅可以开阔我们的视野，还能带我们进入另一个世界，暂时忘却现实的烦恼，享受心灵的宁静。\n深呼吸，一个简单而强大的工具。它能迅速缓解紧张情绪，让我们的心灵回归平和。\n冥想、日记、阅读、深呼吸，这些简单的实践能极大地提升我们的心理健康。让我们从今天开始，为心灵种下幸福与平和的种子。'
            ],
            randomNumber: 0,
            color1: '#ffffff',
            voiceValue: 'zh-CN-YunjianNeural-Male',
            voiceOptions: [{
                value: 'zh-CN-YunjianNeural-Male',
                label: '云锏'
            }],
            bgmOptions: [{
                value: 'random',
                label: '自动匹配'
            }, {
                value: '0',
                label: '无'
            }],
            bgmValue: 'random',
            fontValue: '1',
            fontOptions: [{
                value: '1',
                label: '默认'
            }, {
                value: '2',
                label: '黑体'
            }],
            sizeValue: 40,
            sizeOptions: [
                {
                    value: 20,
                    label: '20px'
                },
                {
                    value: 24,
                    label: '24px'
                },
                {
                    value: 28,
                    label: '28px'
                },
                {
                    value: 32,
                    label: '32px'
                },
                {
                    value: 40,
                    label: '40px'
                },
            ],
            fontColor: '#ffffff',
            borderOptions: [
                {
                    value: 1,
                    label: '1px'
                },
                {
                    value: 2,
                    label: '2px'
                },
                {
                    value: 3,
                    label: '3px'
                },
                {
                    value: 4,
                    label: '4px'
                },
                {
                    value: 5,
                    label: '5px'
                },
            ],
            borderValue: 1,
            positionValue: 'bottom',
            positionOptions: [
                {
                    value: 'bottom',
                    label: '底部'
                },
                {
                    value: 'middle',
                    label: '居中'
                },
                {
                    value: 'top',
                    label: '顶部'
                },
            ],
            timer: null,
            videoUrl: "",
            progress: 0,
            videoList: [],
            key: 0,
            showSet: false,
        }
    },
    computed: {
        textLength() {
            return this.textcontent.length;
        },
    },
    beforeDestroy() {
        clearTimeout(this.timer)   //结束定时器，
    },
    mounted() {
    },
    methods: {
        madeVideo() {
            // 判断是否登录
            if (!localStorage.getItem('conf_id')) {
                this.$message.warning('请先登录账号')
                this.$emit('loginState', true)
                return
            }
            // 判断textcontent
            if (!this.textcontent) {
                this.$message.error('请输入文本内容')
                return
            }
            madeVideoApi({
                video_subject: this.textcontent,
                video_script: "",
                video_terms: "",
                video_aspect: this.proportion,
                video_concat_mode: "random",
                video_clip_duration: 3,
                video_count: 1,
                video_language: "",
                voice_name: this.voiceValue,
                voice_volume: 1.0,
                bgm_type: this.bgmValue,
                bgm_file: "",
                bgm_volume: 0.2,
                subtitle_enabled: this.checked,
                subtitle_position: this.positionValue,
                font_name: "MicrosoftYaHeiBold.ttc",
                text_fore_color: this.fontColor,
                text_background_color: "transparent",
                font_size: +this.sizeValue,
                stroke_color: this.color1,
                stroke_width: +this.borderValue,
                n_threads: 2,
                paragraph_number: 1,
                user_id: localStorage.getItem('conf_id'),
            }).then(res => {
                if (res.status == 200) {
                    if (res.data.data.task_id) {
                        this.$emit('madeProcess', res.data.data.task_id)
                    }
                }
            })
        },
        randomText() {
            if (this.randomNumber >= this.exampleText.length - 1) {
                this.randomNumber = 0
            } else {
                this.randomNumber++
            }
            this.textcontent = this.exampleText[this.randomNumber]
        },
        // 获取处理进度
        getProcess(id) {
            getProcessApi(id).then(res => {
                console.log(res, '进度')
                if (res.data.status === 200) {
                    this.videoList.forEach((item, index) => {
                        if (item.task_id === id) {
                            // this.$set(this.videoList[index],'progress',res.data.data.progress)
                            this.videoList[index].progress = res.data.data.progress;
                            if (res.data.data.progress === 100) {
                                this.videoList[index].status = 1
                            }
                            console.log(this.videoList[index].progress, '进度')
                        }
                    })
                    if (res.data.data.state && res.data.data.state === 4) {
                        this.timer = setTimeout(() => {
                            this.getProcess(id)
                        }, 10000)
                    } else {
                        if (res.data.data.combined_videos && res.data.data.combined_videos.length > 0) {
                            this.videoUrl = res.data.data.combined_videos[0]
                        }
                        if (res.data.data.videos && res.data.data.videos.length > 0) {
                            this.videoUrl = res.data.data.videos[0]
                        }
                        clearTimeout(this.timer)
                    }
                }
            })
        },
        changeShowType(type) {
            this.showType = type
            if (type === 'list') {
                this.getVideolist()
            }
        },
        async getVideolist() {
            const res = await getVideoListApi(localStorage.getItem('conf_id'), 1, 20)
            if (res.status === 200) {
                this.videoList = res.data.data.data
                this.videoList.forEach(item => {
                    if (item.status === 4) {
                        this.getProcess(item.task_id)
                        item.progress = 0
                    }else{
                        item.progress = 100
                    }
                })
            }
        },
        goDetail(url, time) {
            this.$router.push({
                path: '/detail',
                query: {
                    url,time
                }
            })
        },
        goMine() {
            this.$router.push({
                path: '/record',
                query:{
                    listype:2
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.main-page {
    // min-height: calc(100vh - 148px);
    color: #ccc;
    font-size: 15px;

    .main_content {
        // min-height: calc(100vh - 248px);
        width: 100%;
        margin: auto;
        .text_area{
            background: rgba($color: #162936, $alpha: .8);
            border-radius: 15px;
            margin-top: 20px;
            width: 100%;
            border: 1px solid #909399;
            padding: 10px 20px;
            textarea {
                background: transparent;
                resize: none;
                width: 100%;
                color: #fff;
                font-size: 15px;
                border: none;
            }

            textarea:focus-visible {
                outline: none;
            }
        }

        

        .top_tit {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;

            span {
                cursor: pointer;
                background-color: #163572;
                border: 1px solid #163572;
                border-radius: 40px;
                text-align: center;
                line-height: 40px;
                color: #fff;
                padding: 0 20px;
            }
            img{
                vertical-align: middle;
                width: 20px;
                margin-right: 5px;
            }
            .set_btn{
                display: flex;
                align-items: center;
                cursor: pointer;
            }   
        }
        .fun_area{
            display: flex;
            justify-content: space-between;
            align-items: center;
            // margin-top: 10px;
            button{
                background: transparent;
                padding: 6px 20px;
                margin-right: 20px;
                color: #ccc;
                border: 1px solid #ccc;
                border-radius: 5px;
                cursor: pointer;
            }
            span{
                cursor: pointer;
            }
        }
    }

    .set_area {
        background: rgba($color: #162936, $alpha: .8);
        padding: 15px 20px;
        padding-top: 35px;
        border-radius: 15px;
        margin-bottom: 10px;
        ul {
            display: flex;
            // flex-wrap: wrap;
            align-items: flex-start;
            .same_box{
                margin-right: 0;
            }
            li {
                display: flex;
                align-items: center;
                margin-right: 30px;
                margin-bottom: 20px;

                .btn_size {
                    height: 28px;
                    width: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
                    border-radius: 5px;
                    cursor: pointer;
                    margin-bottom: 10px;
                }

                img {
                    vertical-align: middle;
                    margin-right: 10px;
                }

                .set_title {
                    display: inline-block;
                    min-width: 45px;
                    text-align: right;
                    line-height: 28px;
                }
                .sel_box{
                    width: 110px;
                }
            }
        }
        ::v-deep .el-color-picker{
            height: 28px;
        }
        ::v-deep .el-color-picker__trigger{
            width: 28px;
            height: 28px;
        }
        ::v-deep .el-color-picker__mask{
            width: 28px;
            height: 28px;
        }
    }

    .summarize_btn {
        width: 160px;
        height: 40px;
        background-color: #163572;
        border: 1px solid #163572;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        // margin: 0 auto;
        // margin-top: 30px;
        cursor: pointer;
    }

    .list_box {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 25%;
            padding: 20px 10px;
            position: relative;

            video {
                border-radius: 10px;
            }

            .mask_box {
                width: calc(100% - 20px);
                height: calc(100% - 40px);
                position: absolute;
                top: 20px;
                left: 10px;
                border-radius: 10px;
                background-color: rgba($color: #000000, $alpha: .4);
                display: flex;
                justify-content: center;
                align-items: center;

                ::v-deep .el-progress__text {
                    color: #fff !important;
                }
                .start_play{
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    background-color: rgba($color: #ffffff, $alpha: .3);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    i{
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .video_box {
        width: 100%;
        max-height: max-content;
        border-radius: 10px;
        margin: 50px auto;
        overflow: hidden;
        position: relative;

        video {
            border-radius: 10px;
        }
    }
}
::v-deep .el-progress-circle {
    width: 45px !important;
    height: 45px !important;
}
</style>