import axios from 'axios'

import {
    Message
} from 'element-ui'
import router from '../router/index'

import store from '@/store'

// 创建axios 实例
const service = axios.create({
    // baseURL:"http://192.168.66.18:8016/api",
    baseURL: window.location.origin + '/api/',
    // baseURL: 'http://chatmedia.newacademic.net/api/',
    timeout: 50000, // 请求超时时间
    headers: {
        'Cache-Control': 'no-cache' //重点清除接口的缓存
    }
})

service.interceptors.request.use((config) => {
    // 动态设置请求头
    if(config.url !== "/login/" ){
        const token = localStorage.getItem('conf_token')
        // 动态设置请求头
        config.headers = {
            token: token
        }
    }
    return config
}, (err) => {
    // console.log('a1')
    return Promise.reject(err)
})

service.interceptors.response.use((res) => {
    const response = res.data
    if (response.code == 200) {
        return res
    } else if(response.code == 400){
        localStorage.clear();
        //如果当前页面不是登录页面，跳转到登录页面
        if(router.currentRoute.path !== '/'){
            router.push({
                path: '/'
            })
        } 
        return res
    }else{
        Message.error({
            message: res.data.message,
            type: 'error'
        })
        return res
    }
}, (err) => {
    console.log('a')
    return Promise.reject(err)
})

export default service