<template>
    <div class="header">
        <div style="font-size:18px" @click="goDash">
            <img src="@/assets/newLogo.png" style="width: 180px;">
            <slot name="btn"></slot>
        </div>
        <div>
            <span v-if="!loginStatus" @click="openDialog('登录')">登录</span>
            <span v-if="!loginStatus" class="line">|</span>
            <span v-if="!loginStatus" @click="openDialog('注册')">注册</span>
            <el-dropdown v-if="loginStatus">
                <span class="el-dropdown-link">
                    <i class="el-icon-user" style="font-size:25px;color:#fff"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="$router.push('/record')">
                        <img src="@/assets/时钟 (1).png" width="18px">
                        个人中心
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="goOut">
                        <img src="@/assets/退出.png" width="20px">
                        退出登录
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-dialog :title="title" :visible.sync="loginVisible" :width="title === '登录' ? '500px' : '550px'"
            :close-on-click-modal="false" :before-close="closeDialog">
            <el-form :model="loginForm" :rules="loginRules" ref="loginFR" class="demo-ruleForm"
                :label-width="title === '登录' ? '0px' : '110px'">
                <!-- 登录 -->
                <div class="login_top_box" v-if="title === '登录'">
                    <el-form-item prop="username">
                        <el-input prefix-icon="el-icon-user" v-model="loginForm.username"
                            placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input prefix-icon="el-icon-key" ref="passwordTypeThree" :type="passwordTypeThree"
                            v-model="loginForm.password" autocomplete="off" placeholder="请输入密码">
                            <i slot="suffix" @click="showPwd('passwordTypeThree')">
                                <img v-if="passwordTypeThree === 'password'" src="@/assets/闭眼.png"
                                    style="vertical-align: sub;">
                                <img v-else src="@/assets/睁眼.png" style="vertical-align: sub;">
                            </i>
                        </el-input>
                    </el-form-item>
                    <div class="label_box">
                        <el-checkbox v-model="checked" style="color: #fff;"></el-checkbox>
                        <span style="margin-left: 10px;">我已阅读并同意</span><span @click="seeStatement"
                            class="statement_btn">《免责声明》</span>
                    </div>
                </div>

                <!-- 注册 -->
                <div v-if="title === '注册'">
                    <el-form-item label="账户/用户名" prop="username">
                        <el-input prefix-icon="el-icon-user" v-model="loginForm.username"
                            placeholder="请输入账户/用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input prefix-icon="el-icon-key" ref="passwordOne" :type="passwordType"
                            v-model="loginForm.password" autocomplete="off" placeholder="请输入密码">
                            <i slot="suffix" @click="showPwd('passwordOne')">
                                <img v-if="passwordType === 'password'" src="@/assets/闭眼.png"
                                    style="vertical-align: sub;">
                                <img v-else src="@/assets/睁眼.png" style="vertical-align: sub;">
                            </i>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="checkPass">
                        <el-input prefix-icon="el-icon-key" ref="passwordTwo" :type="passwordTypeTwo"
                            v-model="loginForm.checkPass" autocomplete="off" placeholder="请输入密码">
                            <i slot="suffix" @click="showPwd('passwordTwo')">
                                <img v-if="passwordTypeTwo === 'password'" src="@/assets/闭眼.png"
                                    style="vertical-align: sub;">
                                <img v-else src="@/assets/睁眼.png" style="vertical-align: sub;">
                            </i>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input prefix-icon="el-icon-message" v-model="loginForm.email"
                            placeholder="请输入邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone">
                        <el-input prefix-icon="el-icon-mobile-phone" v-model="loginForm.phone"
                            placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="code" v-if="title === '注册'">
                        <el-input prefix-icon="el-icon-chat-square" v-model="loginForm.code" placeholder="请输入验证码"
                            style="width:240px;margin-right: 10px;"></el-input>
                        <el-button type="primary" plain @click="getCodeBtn(loginForm.phone)" id="code" :disabled="dis"
                            style="width:150px;border-color:#2946B5;background-color: rgba(41,70,181,.3);color:#fff">{{
            codeBtn }}</el-button>
                    </el-form-item>
                </div>

                <div style="margin-top: 30px;padding-left: 0;">
                    <div class="fun_box">
                        <el-button @click="loginUse" style="width:120px;background-color: #2946B5;color: #fff;">{{ title
                            }}</el-button>
                        <el-button @click.native="closeDialog"
                            style="width:120px;background-color: #fff;color: #2946B5;">关闭</el-button>
                    </div>
                </div>
                <el-form-item style="text-align: right;">
                    <el-button style="color: #fff;" v-if="title === '登录'" type="text" @click="changeLogin('注册')">
                        注册账号
                    </el-button>
                    <el-button style="color: #fff;" v-else type="text" @click="changeLogin('登录')">
                        已有账号，去登录
                    </el-button>
                </el-form-item>
            </el-form>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">登录</el-button>
                <el-button type="primary" style="background:#3C6ECE;border-color:#3C6ECE" @click="dialogVisible = false">注册</el-button>
            </span> -->
        </el-dialog>
    </div>
</template>

<script>
import { personalLoginApi, ipLoginApi, registerApi, sendMessageApi } from '@/api/chatlogin'
import { getTaskListApi } from '@/api/user'
export default {
    name: "ComHeader",
    props: {
        loginVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else if (value.length < 6 || value.length > 24) {
                callback(new Error("请输入6-24位密码"));
            } else {
                if (this.loginForm.checkPass !== "") {
                    this.$refs.loginFR.validateField("checkPass");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else {
                if (value !== this.loginForm.password) {
                    console.log(value, this.loginForm.checkPass);
                    callback(new Error("两次输入密码不一致!"));
                } else {
                    callback();
                }
            }
        };
        var validatePhoneNumber = (rule, value, callback) => {
            const phonePattern = /^1[3456789]\d{9}$/;
            if (!phonePattern.test(value)) {
                callback(new Error("请输入有效的手机号码"));
            } else {
                callback();
            }
        };
        var namePass = (rule, value, callback) => {
            if (escape(value).indexOf("%u") < 0) {
                if(value.length < 3){
                    callback(new Error('长度在 3 到 20 个字符,一个汉字为两个字符'));
                }else if(value.length > 25){
                    callback(new Error('长度在 3 到 20 个字符,一个汉字为两个字符'));
                }else{
                    callback()
                }
            } else if(escape(value).indexOf("%u") > 0){
                const length = value.match(/[\u4E00-\u9FA5]/g).length
                if(length + value.length < 3){
                    callback(new Error('长度在 3 到 20 个字符,一个汉字为两个字符'));
                }else if(length + value.length > 25){
                    callback(new Error('长度在 3 到 20 个字符,一个汉字为两个字符'));
                }else{
                    callback()
                }
            }else{
                callback();
            }
        }
        return {
            property: 'value',
            checked: false,
            // dialogVisible:false,
            loginForm: {
                email: '',
                password: '',
                code: '',
                username: '',
                phone: '',
                checkPass: ''
            },
            loginRules: {
                email: [
                    { required: true, message: "请输入邮箱地址", trigger: "blur" },
                    {
                        type: "email",
                        message: "请输入正确的邮箱地址",
                        trigger: ["blur", "change"],
                    },
                ],
                password: [
                    { required: true, validator: validatePass, trigger: 'blur' }
                ],
                username: [
                    { required: true, message: "请输入账号/用户名", trigger: "blur" },
                    { validator: namePass, trigger: "blur" }
                ],
                phone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    {
                        validator: validatePhoneNumber,
                        trigger: "blur",
                    },
                ],
                code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
                checkPass: [{ required: true, validator: validatePass2, trigger: "blur" }],
            },
            passwordType: 'password',
            passwordTypeTwo: 'password',
            passwordTypeThree: 'password',
            title: '登录',
            codeBtn: '获取验证码',
            count: '',
            dis: false,
            loginStatus: false,
            chatkey: '',
            organ_code: '',
            loginState: 'ip'
        };
    },
    
    created() {
        //从头部获取userName，userId，token
        if (this.$route.query.userName && this.$route.query.userId && this.$route.query.token) {
            this.userName = this.$route.query.userName
            this.user_id = this.$route.query.userId
            // 向本地存储存入token， userId
            localStorage.setItem('conf_token', this.$route.query.token)
            localStorage.setItem('conf_id', this.$route.query.userId)
            // 修改登录状态
            this.loginStatus = true
            // 清除路由query
            this.$router.replace({ path: '/', query: {} })
        }

        if (localStorage.getItem('conf_token') && localStorage.getItem('conf_id')) {
            this.loginStatus = true
        } else {
            this.loginStatus = false
        }
        // 检测token是否过期
        if (localStorage.getItem('conf_token') && localStorage.getItem('conf_id') && this.$route.path == '/') {
            this.getList()
        }
    },
    mounted() {
        if (this.$route.query.chatkey && this.$route.query.organ_code) {
            this.chatkey = this.$route.query.chatkey
            this.organ_code = this.$route.query.organ_code
            this.loginState = 'org'
        }
    },
    methods: {
        async getList() {
            const res = await getTaskListApi(1, 10, this.user_id)
            if (res.data.code === 400) {
                this.loginStatus = false
            }
        },
        seeStatement() {
            // 打开public文件下的html文件
            window.open('/statement.html', '_blank')
        },
        goDash() {
            if (this.$route.path != '/') {
                this.$router.push('/')
            }
        },
        loginUse() {
            console.log(this.checked, 'checked')
            this.$refs.loginFR.validate((valid) => {
                if (valid) {
                    if (this.title === '登录') {
                        if (!this.checked) {
                            this.$message.warning('请您阅读并同意《免责声明》')
                            return
                        } else {
                            personalLoginApi({
                                username: this.loginForm.username,
                                password: this.loginForm.password,
                            }).then(res => {
                                console.log(res)
                                if (res.code === 0) {
                                    this.$emit('changevisible', false, res.data.token)
                                    this.$refs.loginFR.resetFields()
                                    this.loginStatus = true
                                    localStorage.setItem('conf_token', res.data.token)
                                    localStorage.setItem('conf_id', res.data.user_id)
                                    this.$message.success('登录成功')
                                } else {
                                    // this.$message.error(res.data.message)
                                }
                            })
                        }
                    } else {
                        let form = {
                            username: this.loginForm.username,
                            password: this.loginForm.password,
                            email: this.loginForm.email,
                            phone: this.loginForm.phone,
                            code: this.loginForm.code,
                            organ_code: this.organ_code
                        }
                        if (this.loginState === 'org') {
                            form.token = this.chatkey
                        }
                        registerApi(form).then(res => {
                            if (res.code === 0) {
                                this.$message.success('注册成功')
                                this.title = '登录'
                                // this.$refs.loginFR.resetFields()
                            }
                        })
                    }
                }
            })
        },
        closeDialog() {
            this.$emit('changevisible', false)
            this.$refs.loginFR.resetFields()
        },
        showPwd(pass) {
            if (pass === 'passwordOne') {
                this.passwordType = this.passwordType === 'password' ? '' : 'password'
            } else if (pass === 'passwordTwo') {
                this.passwordTypeTwo = this.passwordTypeTwo === 'password' ? '' : 'password'
            } else {
                this.passwordTypeThree = this.passwordTypeThree === 'password' ? '' : 'password'
            }
            this.$nextTick(() => {
                this.$refs[pass].focus()
            })
        },
        // ip验证
        ipCheck(type, txt) {
            // type - open:打开弹窗  change:切换弹窗
            if (!this.chatkey && !this.organ_code) {
                ipLoginApi().then(res => {
                    if (res.code === 0) {
                        // ip验证通过 打开弹窗
                        this.chatkey = res.data.token
                        this.organ_code = res.data.organ_code
                        if (type === 'open') {
                            this.$emit('changevisible', true)
                        } else {
                            this.$refs.loginFR.resetFields()
                            this.title = txt
                        }
                    } else {
                        window.location.href = 'https://chatlibrary.newacademic.net/?chatmedia=true'
                    }
                })
            } else {
                if (type === 'open') {
                    this.$emit('changevisible', true)
                } else {
                    this.$refs.loginFR.resetFields()
                    this.title = txt
                }
            }
        },
        changeLogin(txt) {
            if (txt === '注册') {
                // 判断是否在ip段范围内
                this.ipCheck('change', txt)
            } else {
                this.title = txt
                if (this.$refs.loginFR) {
                    this.$refs.loginFR.resetFields()
                }
            }
        },
        openDialog(txt) {
            // window.location.href = 'http://192.168.66.192:8084/?chatmedia=true'
            this.title = txt
            // 判断是否在ip段范围内
            if (txt === '注册') {
                this.ipCheck('open')
            } else {
                this.$emit('changevisible', true)
            }
        },
        // 获取验证码
        async getCodeBtn() {
            let time = 59
            this.$refs.loginFR.validateField("phone", async (errorMsg) => {
                if (errorMsg) {
                } else {
                    this.dis = true
                    this.count = time
                    let form = new FormData()
                    form.append('phone', this.loginForm.phone)
                    form.append('type', '1')
                    form.append('ara_code', '+86')
                    const res = await sendMessageApi(form)
                    console.log(res, 'code')
                    if (res.code === 0) {
                        this.$message.success('发送成功')
                    }
                    let timer = setInterval(() => {
                        // 判断剩余秒数
                        if (this.count == 0) {
                            // 清除定时器和复原按钮
                            clearInterval(timer);
                            this.dis = false;
                            this.codeBtn = '获取验证码';
                        } else {
                            this.codeBtn = `重新获取(${this.count}s)`;
                            this.count--;
                        }
                    }, 1000);
                }
            });
        },
        goOut() {
            this.$router.push('/')
            localStorage.removeItem('conf_token')
            localStorage.removeItem('conf_id')
            this.loginStatus = false
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    height: 60px;
    background-color: #09204C;
    padding: 0 15%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .line {
        margin: 0 10px;
    }

    div {
        cursor: pointer;
    }

    span {
        line-height: 20px;

        img {
            width: 20px;
            height: 20px;
            margin-left: 5px;
            vertical-align: sub;
        }
    }
}

.fun_box {
    width: 100%;
    display: flex;
    justify-content: space-between;

    ::v-deep .el-button {
        width: 48% !important;
        border-color: #011E33;
    }
}

.label_box {
    display: flex;
}

.statement_btn {
    color: #409EFF;
    cursor: pointer;
}

::v-deep .el-dialog {
    border-radius: 10px;
    background-color: #011E33;
    color: #fff;
}

::v-deep .el-dialog__title {
    color: #fff !important;
}

::v-deep .el-dialog__body {
    color: #fff;
}

::v-deep .el-progress__text {
    color: #fff !important;
}

::v-deep .el-dialog__footer {
    display: flex;
    justify-content: center;
}

::v-deep .el-form-item__label {
    color: #fff;
}

::v-deep .el-checkbox {
    display: flex;
    align-items: center;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #fff;
}

img {
    vertical-align: middle;
}
</style>

<style scoped>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    color: #163572;
}
</style>