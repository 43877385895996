<template>
    <div>
        <comHeader></comHeader>
        <div class="main-page">
            <div class="change_area">
                <div @click="changeList(1)" :class="listype === 1 ? 'active' : ''">总结记录</div>
                <div @click="changeList(2)" :class="listype === 2 ? 'active' : ''">生成记录</div>
            </div>
            <div style="padding-top:30px;">
                <div class="task_list"  v-if="(taskList.length > 0 && listype === 1) || (videoList.length > 0 && listype === 2)">
                    <ul v-if="listype === 1">
                        <li v-for="(item, index) in taskList" :key="index">
                            <div class="task_left">
                                <div class="task_left_img"
                                    @click="goVideoDetail(item.video_url, item.article_id, item.status, item.Simplify_detail)">
                                    <video v-if="item.download_video && +item.status >= 1"
                                        style="width:170px;height:110px" :src="item.download_video"></video>

                                    <!-- <img v-if="item.cover"
                                        :src="item.cover.includes('http') ? item.cover : 'http://192.168.66.18:8085' + item.cover"
                                        width="150px" style="vertical-align: bottom;"> -->
                                    <img v-else src="@/assets/bgc2.png" width="170px" height="110px"
                                        style="vertical-align: bottom;">
                                </div>
                                <div class="task_left_info">
                                    <p class="task_info_bottom"
                                        @click="goVideoDetail(item.video_url, item.article_id, item.status, item.Simplify_detail)">
                                        <span>标题：</span>
                                        <span style="width:80%">{{ item.video_title ? item.video_title : item.filename
                    ? item.filename : item.video_url }}</span>
                                    </p>
                                    <p class="task_info_bottom">添加时间：{{ item.start_time }}</p>
                                    <p class="task_info_schedule">
                                        解析状态：
                                        <el-button style="background: #294FD3;" v-if="+item.status == 3" size="mini"
                                            :disabled="item.status != '3'"
                                            @click="goVideoDetail(item.video_url, item.article_id, item.status, item.Simplify_detail)">
                                            已完成（查看详情）
                                        </el-button>
                                        <el-button style="background: #7FCD13;"
                                            v-else-if="+item.status >= 0 && +item.status < 3" size="mini"
                                            :disabled="item.status != '3'">
                                            解析中
                                        </el-button>
                                        <span v-else>
                                            <el-button style="background: #BBBBBB;" size="mini" disabled>
                                                解析失败
                                            </el-button>
                                            <el-button v-if="item.status == '-3'" @click="summarizeAgain(item.id)"
                                                type="text" style="color: rgb(41, 79, 211);font-weight: 700;"
                                                size="mini">
                                                <span style="text-decoration: underline;">重试</span> <i
                                                    style="font-weight: 700;" class="el-icon-refresh-right"></i>
                                            </el-button>
                                        </span>
                                    </p>
                                    <p class="task_info_bottom video_info_btn"
                                        v-if="item.video_url && item.video_url.includes('http')">
                                        <a style="color:#A9A5A2;margin-right: 3px;line-height: 20px;margin-bottom: 1px;"
                                            :href="item.video_url" target="_blank">查看源视频</a>
                                        <el-tooltip content="点击此处,可跳转至该视频的原始链接" placement="bottom" :offset="100"
                                            :visible-arrow="false">
                                            <el-button style="padding: 0;border: none;"><img
                                                    src="../assets/md-info_outline 1@1x.png" width="19px"></el-button>
                                        </el-tooltip>

                                    </p>
                                </div>
                            </div>
                            <div class="tesk_right">
                                <el-button class="btn" style="background:#253E6E"
                                    @click="deleteItem(item.article_id, 1)">删除</el-button>
                            </div>
                        </li>
                    </ul>
                    <ul v-else>
                        <li v-for="(item, index) in videoList" :key="index">
                            <div class="task_left">
                                <div class="task_left_img">
                                    <div class="mask_box" v-if="item.status === 1 && item.video_url !== 'False'">
                                        <div class="start_play" @click="seeVideo(item.video_url)">
                                            <i class="el-icon-caret-right"></i>
                                        </div>
                                    </div>
                                    <video v-if="item.video_url && +item.status === 1 && item.video_url !== 'False'"
                                        style="width:170px;height:110px" :src="item.video_url"></video>
                                    <img v-else src="@/assets/bgc2.png" width="170px" height="110px"
                                        style="vertical-align: bottom;">
                                </div>
                                <div class="task_left_info">
                                    <p class="task_info_bottom" @click="seeVideo(item.video_url)">
                                        <span>标题：</span>
                                        <span style="width:80%">{{ item.video_text }}</span>
                                    </p>
                                    <p class="task_info_bottom">添加时间：{{ item.create_time }}</p>
                                    <p class="task_info_schedule">
                                        状态：
                                        <el-button style="background: #294FD3;"
                                            v-if="+item.status == 1 && item.video_url !== 'False'" size="mini"
                                            :disabled="item.status !== 1" @click="seeVideo(item.video_url)">
                                            已生成
                                        </el-button>
                                        <el-button style="background: #7FCD13;" v-else-if="+item.status === 4"
                                            size="mini" :disabled="item.status != '3'">
                                            {{ item.progress }}%
                                        </el-button>
                                        <span v-else>
                                            <el-button style="background: #BBBBBB;" size="mini" disabled>
                                                生成失败
                                            </el-button>
                                            <el-button v-if="item.status == '-3'" @click="summarizeAgain(item.id)"
                                                type="text" style="color: rgb(41, 79, 211);font-weight: 700;"
                                                size="mini">
                                                <span style="text-decoration: underline;">重试</span> <i
                                                    style="font-weight: 700;" class="el-icon-refresh-right"></i>
                                            </el-button>
                                        </span>
                                        <el-button type="text" v-if="+item.status == 1 && item.video_url !== 'False'"
                                            size="mini" style="padding:0;color:#294FD3"
                                            @click="downLoadVideo(item.video_url)">
                                            <img src="../assets/下载 (9).png" width="18px"
                                                style="vertical-align: middle;margin-right:5px">下载
                                        </el-button>
                                    </p>
                                </div>
                            </div>
                            <div class="tesk_right">
                                <el-button class="btn" style="background:#253E6E"
                                    @click="deleteItem(item.id, 2)">删除</el-button>
                            </div>
                        </li>
                    </ul>
                    <div class="page_fun" v-if="total > 10">
                        <el-pagination background layout="prev, pager, next" :total="total" :prev-text="'上一页'"
                            :next-text="'下一页'" :current-page="page" :page-size="pageSize"
                            @current-change="handleCollectChange">
                        </el-pagination>
                    </div>
                </div>
                <div class="have_no"
                    v-if="(taskList.length <= 0 && listype === 1) || (videoList.length <= 0 && listype === 2)">
                    <img src="@/assets/暂无数据 (2).png" alt="">
                    暂无数据
                </div>
            </div>
        </div>
        <comFooter></comFooter>
    </div>
</template>

<script>
import comHeader from '@/components/ComHeader.vue'
import comFooter from '@/components/ComFooter.vue'
import { delTaskItemApi, getTaskListApi, againSummaryApi } from '@/api/user.js'
import { madeVideoApi, getProcessApi, getVideoListApi, delVideoApi } from '../api/textTo'
import axios from 'axios'
export default {

    data() {
        return {
            taskList: [],
            isFetching: false,
            buttonText: '',
            total: 0,
            timer: null,
            pageSize: 10,
            page: 1,
            userId: '',
            listype: 1,
            videoList: [],
        }
    },
    components: {
        comHeader,
        comFooter,
    },
    mounted() {
        this.userId = localStorage.getItem('conf_id')
        if (this.$route.query.listype) {
            this.listype = +this.$route.query.listype
        } else {
            this.listype = 1
        }
        if (this.listype === 1) {
            this.getTaskList()
        } else {
            this.getVideolist()
        }
    },
    beforeDestroy() {
        clearTimeout(this.timer)   //结束定时器，
    },
    methods: {
        // 视频任务列表
        async getTaskList() {
            if (this.isFetching) return;
            this.isFetching = true;
            try {
                const res = await getTaskListApi(this.page, this.pageSize, this.userId)
                if (res.data.code === 200) {
                    var dayjs = require('dayjs')
                    res.data.data.video.forEach(item => {
                        item.buttonText = +item.status
                            >= 0 && +item.status < 3 ? '解析中' : +item.status == 3 ? '已完成' : '解析失败'
                        if (item.start_time !== '') {
                            item.start_time = item.start_time.replace(/T/g, " ")
                            item.start_time = item.start_time.replace(/Z/g, "")
                        }
                    })
                    this.taskList = res.data.data.video
                    this.total = res.data.data.counts
                    let newArr = []
                    res.data.data.video.forEach(item => {
                        if (+item.status >= 1) {
                            newArr.push(item)
                        }
                    })
                    const through = newArr.every(obj => obj.status == '3')
                    // 判断
                    if (!through) {
                        this.timer = setTimeout(() => {
                            this.getTaskList()
                        }, 30000)
                    } else {
                        clearTimeout(this.timer)
                        return
                    }
                }
            } catch (error) {
                // 处理错误
                console.log(error);
            } finally {
                this.isFetching = false;
            }

        },
        async summarizeAgain(id) {
            const res = await againSummaryApi({
                task_id: id,
                Simplify_detail: 1
            })
            if (res.data.code === 200) {
                this.getTaskList()
            }
        },
        // 删除
        deleteItem(id, type) {
            this.$confirm(`确定要删除该条任务吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                if (type === 1) {
                    const res = await delTaskItemApi(this.userId, id)
                    if (res.data.code === 200) {
                        this.$message.success(res.data.message)
                        // 判断当前页面条数
                        if (this.taskList.length === 1) {
                            this.page = this.page - 1
                        }
                        this.getTaskList()
                    } else {
                        this.$message.success(res.data.message)
                    }
                } else {
                    const res = await delVideoApi(id)
                    if (res.data.code === 200) {
                        this.$message.success(res.data.message)
                        // 判断当前页面条数
                        if (this.videoList.length === 1) {
                            this.page = this.page - 1
                        }
                        this.getVideolist()
                    } else {
                        this.$message.warning(res.data.message)
                    }
                }
            })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '您取消了删除',
                    })
                })

        },
        goVideoDetail(add, id, status, audio) {
            if (status == '3') {
                this.$router.push({
                    path: '/home',
                    query: {
                        add, id
                    }
                })
                localStorage.removeItem('article_id')
                localStorage.setItem('radio_conf', audio)
            } else if (+status >= 0 && +status < 3) {
                this.$message({
                    type: 'info',
                    message: '视频解析中，请稍后',
                })
            } else {
                this.$message({
                    type: 'info',
                    message: '视频解析失败，请重新上传',
                })
            }
        },
        handleCollectChange(val) {
            this.page = val
            if (this.listype === 1) {
                this.getTaskList()
            } else {
                this.getVideolist()
            }
        },

        /**
         * 文生视频部分列表及功能
         * @param {String} url 视频地址
         */
        async getVideolist() {
            const res = await getVideoListApi(localStorage.getItem('conf_id'), this.page, this.pageSize)
            if (res.status === 200) {
                this.videoList = res.data.data.data
                this.total = res.data.data.count
                this.videoList.forEach(item => {
                    if (item.status === 4) {
                        this.getProcess(item.task_id)
                        item.progress = 0
                    } else if (item.status === 1) {
                        item.progress = 100
                    }
                    if (item.create_time !== '') {
                        item.create_time = item.create_time.replace(/T/g, " ")
                    }
                })
            }
        },
        changeList(type) {
            this.listype = type
            this.page = 1
            this.$router.push({
                path: '/record',
                query: {
                    listype: type
                }
            })
            this.total = 0
            if (this.listype === 1) {
                this.getTaskList()
            } else {
                this.getVideolist()
            }
        },
        downLoadVideo(url) {
            // 将视频下载至本地
            axios({
                method: 'get',
                url: url,
                responseType: 'blob',
            }).then(res => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([res.data]))
                link.download = url
                link.click()
            })
        },
        // 获取处理进度
        getProcess(id) {
            getProcessApi(id).then(res => {
                if (res.data.code === 200) {
                    this.videoList.forEach((item, index) => {
                        if (item.task_id === id) {
                            this.$set(this.videoList, index, {
                                ...this.videoList[index],
                                progress: res.data.data.progress,
                                status: res.data.data.state,
                                video_url: res.data.data.state === 1 ? res.data.data.videos[0] : ''
                            });
                        }
                    })
                    if (res.data.data.state && res.data.data.state === 4) {
                        this.timer = setTimeout(() => {
                            this.getProcess(id)
                        }, 10000)
                    } else {
                        // 判断this.videoList中是否还有status为4的数据
                        const through = this.videoList.every(obj => obj.status == 4)
                        // 判断 如果没有状态为4的数据 则停止定时器
                        if (through) {
                            clearTimeout(this.timer)
                        }
                    }
                }
            })
        },
        seeVideo(url) {
            if (url !== 'False') {
                window.open(url, '_blank')
            } else {
                this.$message({
                    type: 'info',
                    message: '视频生成失败',
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.main-page {
    height: calc(100vh - 148px);
    padding: 30px 15%;
    overflow: auto;

    .change_area {
        display: flex;
        width: 200px;
        margin-bottom: 20px;
        border-radius: 4px;
        overflow: hidden;
        overflow: hidden;

        div {
            width: 50%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
            background: #fff;
            border: 1px solid #013a89;
            // border-radius: 5px;
            color: #013a89;
            transition: all 0.3s;

            &:first-child {
                border-radius: 4px 0 0 4px;
            }

            &:last-child {
                border-radius: 0 4px 4px 0;
            }
        }

        .active {
            background: #013a89;
            color: #fff;
        }
    }

    .task_list {
        ul {
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                border-bottom: 1px solid #ccc;
                padding-bottom: 15px;

                .task_left {
                    display: flex;
                    width: calc(100% - 80px);

                    .task_left_img {
                        width: 172px;
                        height: 110px;
                        margin-right: 20px;
                        background-color: #000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        position: relative;

                        img {
                            width: 100%;
                            height: 110px;
                        }
                        .mask_box {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: rgba($color: #000000, $alpha: .4);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            z-index: 2;

                            ::v-deep .el-progress__text {
                                color: #fff !important;
                            }

                            .start_play {
                                width: 30px;
                                height: 30px;
                                border-radius: 15px;
                                background-color: rgba($color: #ffffff, $alpha: .3);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;

                                i {
                                    font-size: 20px;
                                }
                            }
                        }
                    }

                    .task_left_info {
                        display: flex;
                        width: calc(100% - 192px);
                        flex-direction: column;
                        justify-content: space-between;
                        font-size: 14px;
                        // padding: 8px 0;
                        color: #555;

                        .task_info_bottom {
                            display: flex;
                            justify-content: flex-start;

                            span {
                                display: block;
                                // width: 80%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                cursor: pointer;

                                &:hover {
                                    color: #1A48CF;
                                }
                            }

                            img {
                                vertical-align: middle;
                            }

                        }

                        .task_info_schedule {
                            ::v-deep .el-button {
                                color: #fff;
                            }
                        }
                    }
                }

                .tesk_right {
                    ::v-deep .el-button {
                        width: 78px;
                        text-align: center;
                        color: #fff;
                        transition: background-color 0.3s, color 0.3s;
                        padding: 12px 0;


                    }

                    .btn {
                        &:hover {
                            color: #fff;
                            background-color: #F56C6C !important;
                        }
                    }
                }


            }
        }
    }

    .have_no {
        height: 100%;
        display: flex;
        flex-direction: column;
        color: #606266;
        align-items: center;
        justify-content: center;

        img {
            height: 300px;
        }
    }

}

.page_fun {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #163572;
    border-color: #163572;
}

.video_info_btn {

    ::v-deep .el-button:focus,
    .el-button:hover {
        background-color: transparent;
    }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
    // color: #163572;
}
</style>
