import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashBoard from '../views/DashBoard.vue' 
import Record from '../views/record.vue'
import Router from 'vue-router'
Vue.use(VueRouter)

// 解决路由重复导航报错问题
// 获取原型对象上的push函数
const originalPush = Router.prototype.push
// 修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/home',
        name: 'home',
        component: HomeView
      },
      {
        path: '/',
        name: 'DashBoard',
        component: DashBoard
      },
      {
        path: '/record',
        name: 'Record',
        component: Record
      }
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//     const id = localStorage.getItem('Pub_token')
    
//     if (to.path === '/' && id) {
//         // 如果token存在 去登录页 跳转首页
//         next('/listDetail')
//     } else if (to.path !== '/' && !id) {
//         // 如果token不存在去非登录页 跳登录页
//         return next('/')
//     } else {
//         console.log(id,to);
//         next()
//     }
   
// })

export default router
