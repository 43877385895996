<template>
    <div id="videoJsCon" style="width:100%;">
        <!-- crossOrigin="anonymous" -->
        <video crossOrigin="anonymous" style="width:100%;" id="main_video" ref="videoP" class="video-js vjs-default-skin"
            controls preload="auto">
            <source type="video/mp4" :src="url + gettimes()">
            <!-- <track v-for="(track, index) in subtitleTracks" :key="index" :src="track.src" kind="subtitles"
                :srclang="track.srclang" :label="track.label"> -->
        </video>
    </div>
</template>
<script>

import 'video.js/dist/video-js.css';
import video_zhCN from 'video.js/dist/lang/zh-CN.json'
import videojs from 'video.js';
import 'videojs-contrib-hls';
import { set } from 'vue';
export default {
    data() {
        return {
            selectedSubtitle: null,

            videoPlayer: null,
            // canOut:false,
            // canIn:true
        };
    },
    watch: {
        url(newUrl) {
            console.log('newUrl', newUrl);
            // 如果url变化，销毁原来的播放器 重新初始化播放器
            if (newUrl) {
                this.initVideo();
                this.$nextTick(() => {
                    this.addShot()
                })
            }
        },
        bigTime(newTime) {
            if (newTime) {
                this.chechTime(newTime)
            }
        }
    },

    mounted() {

    },
    props: {
        url: {
            type: String,
            default: ''
        },
        // subtitleTracks: {
        //     type: Array,
        //     default: () => { }
        // },
        bigTime: {
            type: Number,
            default: 0
        },
        // vttSrc:{
        //     type:String,
        //     default:''
        // }
    },
    methods: {
        initVideo() {
            videojs.addLanguage('zh-CN', video_zhCN)
            this.$nextTick(() => {
                this.videoPlayer = videojs(this.$refs.videoP, {
                    controls: true,
                    preload: 'auto',
                    fluid: true,
                    language: 'zh-CN', // 设置语言   
                    width: '769px',
                    playbackRates: [0.5, 1, 1.5, 2],
                });

                this.videoPlayer.on('ready', () => {
                    // this.setupSubtitleSwitch();
                });
            });
        },
        setupSubtitleSwitch() {
            // 判断是否已经添加过字幕
            const existingTracks = this.videoPlayer.remoteTextTracks();
            const existingLanguages = Array.from(existingTracks).map(track => track.language);

            this.subtitleTracks.forEach((track, index) => {
                if (!existingLanguages.includes(track.srclang)) {
                    this.videoPlayer.addRemoteTextTrack({
                        kind: 'subtitles',
                        src: track.src,
                        srclang: track.srclang,
                        label: track.label,
                        default: index === 0 // Set the first subtitle track as default
                    });
                }
            });
            this.selectedSubtitle = this.subtitleTracks[0].srclang;
        },
        toggleSubtitle() {
            const currentTrack = this.videoPlayer.textTracks().tracks.find(track => track.language === this.selectedSubtitle);
            if (currentTrack) {
                currentTrack.mode = 'disabled';
            }

            const newTrack = this.videoPlayer.textTracks().tracks.find(track => track.language !== this.selectedSubtitle);
            if (newTrack) {
                newTrack.mode = 'showing';
                this.selectedSubtitle = newTrack.language;
            }
        },
        chechTime(time) {
            // console.log('time', time);
            this.videoPlayer.currentTime(time);

        },
        // 添加下载视频按钮
        downVideo() {
            const selectBox = document.querySelector('.vjs_select_box')
            selectBox.style.display = 'block'
            const vjsControlBar = document.querySelector('.vjs-control-bar');
            // 监听vjsControlBar的style的opacity属性变化，当opacity为0时隐藏下载视频类型下拉框

            const typeOne = document.querySelector('.tyoe_one')
            const typeTwo = document.querySelector('.tyoe_two')
            vjsControlBar.addEventListener('mouseleave', () => {
                let timer
                timer = setTimeout(() => {
                    // 判断鼠标是否在下载视频类型下拉框中
                    const isDown = document.querySelector('.vjs_select_box:hover')
                    if (!isDown) {
                        selectBox.style.display = 'none'
                        clearTimeout(timer)
                    }
                }, 200)
            })
            typeOne.addEventListener('click', () => {
                selectBox.style.display = 'none'
                this.$emit('downVideo', 1)
            })
            typeTwo.addEventListener('click', () => {
                selectBox.style.display = 'none'
                this.$emit('downVideo', 2)
            })

        },
        addShot() {
            const shotBtn = document.querySelector('.screenshotBtn');
            if (!shotBtn) {
                // 获取vjs-control-bar
                const vjsControlBar = document.querySelector('.vjs-control-bar');
                const downBtn = document.createElement('button')
                const btn = document.createElement('button');
                downBtn.className = 'vjs-control vjs-button downVideoBtn';
                btn.className = 'vjs-control vjs-button screenshotBtn';
                // 按钮添加title属性
                downBtn.title = '下载视频'
                btn.title = '截图';
                // 创建一个img标签
                const videoImg = document.createElement('img')
                const img = document.createElement('img');
                // 设置img标签的src属性\
                videoImg.src = require('@/assets/receive.png');
                img.src = require('@/assets/截图 (2).png');
                // 图片宽度为14px
                videoImg.width = 14;
                img.width = 14;
                // 添加样式vertical-align: sub;使图片垂直居中
                videoImg.style.verticalAlign = 'sub';
                img.style.verticalAlign = 'sub';
                videoImg.style.cursor = 'pointer';
                img.style.cursor = 'pointer';
                // 将img标签添加到button标签中
                downBtn.appendChild(videoImg)
                btn.appendChild(img);

                // 添加下载视频类型下拉框
                let selectBox = document.createElement('div')
                let typeList = `
                    <div class="type_list tyoe_one" style="border-bottom: 1px solid #ccc;padding-bottom: 10px;margin-bottom: 10px;">原音视频</div>
                    <div class="type_list tyoe_two">译音视频</div>
                `
                selectBox.className = 'vjs_select_box'
                selectBox.style.display = 'none'
                selectBox.innerHTML = typeList
                vjsControlBar.appendChild(selectBox)
                // 给button标签添加点击事件
                btn.addEventListener('click', () => {
                    // 调用截图方法
                    this.screenshotHandle();
                });
                downBtn.addEventListener('mouseenter', () => {
                    // 调用下载视频方法
                    this.downVideo();
                });
                selectBox.addEventListener('mouseenter', () => {
                    // 调用下载视频方法
                    selectBox.style.display = 'block'
                });
                selectBox.addEventListener('mouseleave', () => {
                    setTimeout(() => {
                        // 判断鼠标是否在判断鼠标是否在downBtn中
                        const isDown = document.querySelector('.downVideoBtn:hover')
                        if (!isDown) {
                            selectBox.style.display = 'none'
                        } else {
                            selectBox.style.display = 'block'
                        }
                    }, 1000)

                });
                // 将button标签添加到vjs-control-bar标签中
                vjsControlBar.appendChild(downBtn);
                vjsControlBar.appendChild(btn);
            }
        },
        // 截图
        screenshotHandle() {
            const fileType = 'png'
            // 找到需要截图的video标签
            // video 实列
            var video = document.getElementsByTagName('video')[0]
            var canvas = document.createElement('canvas')
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            const ctx = canvas.getContext('2d')
            // 绘制图片
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height) // 图片大小和视频分辨率一致
            console.log('ctx', ctx);
            const strDataURL = canvas.toDataURL('image/' + fileType) // canvas中video中取一帧图片并转成dataURL
            let arr = strDataURL.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            const blob = new Blob([u8arr], {
                type: mime
            })
            const url = window.URL.createObjectURL(blob)
            this.downloadFile(url, 'png')


        },
        // 下载
        downloadFile(blob, fileType) {
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = blob
            // const time = this.parseTime(new Date())
            const time = new Date().getTime()
            a.download = `${time}.${fileType}`
            document.body.appendChild(a)
            a.click()
            setTimeout(function () {
                document.body.removeChild(a)
                window.URL.revokeObjectURL(blob)
            }, 1000)
        },
        // upload
        reload() {
            this.$nextTick(() => {
                // this.videoPlayer.dispose();
                setTimeout(() => {
                    console.log('this.videoPlayer', this.videoPlayer);
                    this.videoPlayer.src(this.url)
                    this.videoPlayer.load()
                    this.videoPlayer.play();  // 重新播放视频
                }, 500)

            })
        },
        gettimes() {
            //生成时间戳
            var timestamp = Date.parse(new Date());
            var text = "?t=" + timestamp;
            return text;
        },
        pictureIn() {
            if (this.videoPlayer) {
                // 获取当前视频播放时间
                const bigTime = this.videoPlayer.currentTime();
                this.$emit('changeminiTime', bigTime)
                // 当前视频暂停播放
                this.videoPlayer.pause();
            }
        },
        outPicture() {
            // 判断当前是否为画中画模式
            // 判断当前视频的播放状态
            if (this.videoPlayer) {
                // 获取当前视频播放状态
                const isPaused = this.videoPlayer.paused();
                // 如果是暂停状态
                if (isPaused) {
                    this.videoPlayer.load();
                    // 播放视频
                    const play = this.videoPlayer.play();
                    // this.videoPlayer.currentTime(this.bigTime);
                    play.then(() => {
                        this.videoPlayer.currentTime(this.bigTime);
                    }).catch((e) => {
                        // console.log('play error', e);
                    });
                }
            }
        }
    },
    beforeDestroy() {
        if (this.videoPlayer) {
            this.videoPlayer.dispose(); // Removing Players,该方法会重置videojs的内部状态并移除dom
        }
    }
};
</script>

<style>
::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(190, 235, 231, 0.1);

}

/* Add your custom styles here */
.vjs-modal-dialog.vjs-text-track-settings {
    background-color: #2B333F !important;
    /* background-color: #fff; */
    color: #fff;
    height: 65%;
    width: 70%;
    margin: 9.5% 15%;
    border-radius: 18px
}

.vjs-text-track-settings fieldset {
    text-align: center;
}

.vjs-text-track-settings legend {
    margin-bottom: 5px;
}

.vjs-track-settings-controls button {
    padding: 3px 5px;
    /* background-color: transparent !important;
    color: #fff; */
}

.vjs-text-track-settings fieldset span>select {
    background-color: #181C23;
    color: #fff;
    padding: 2px 1px;
}

.vjs-track-setting>select {
    margin-right: 0;
    background-color: #181C23;
    color: #fff;
    padding: 2px 1px;
}

.vjs-default-skin.vjs-fluid:not(.vjs-audio-only-mode) {
    padding-top: 56% !important;
}

.type_list {
    color: #fff;
    margin: 5px 0;
    font-size: 12px;
    cursor: pointer;
}

.vjs_select_box {
    position: absolute;
    right: 15px;
    bottom: 30px;
    width: 80px;
    padding: 5px 0;
    text-align: center;
    background-color: rgba(43, 51, 63, 0.7);
}
</style>

