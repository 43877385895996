import request from '@/utils/request';

export function madeVideoApi(data) {
    return request({
        url: 'text_video',
        method: 'post',
        data
    })
}

export function getProcessApi(data) {
    return request({
        url: 'text_video_task?task_id=' + data,
        method: 'get'
    })
}

export function getVideoListApi(id,page,pagesize) {
    return request({
        url: `text_video_list?page=${page}&page_size=${pagesize}&user_id=${id}`,
        method: 'get',
    })
}

export function delVideoApi(data) {
    return request({
        url: `text_video_del?id=${data}`,
        method: 'get',
    })
}