import request from '@/utils/request';

/**
 * 上传任务
 * @param query 
 * @returns 
 */
export const getVideoProcessApi = (query) => {
    return request({
        url: '/video_process',
        method: 'get',
        params:query
    })
}
/**
 * 上传eccel文件
 * @param {*} query 
 * @returns 
 */
export const uploadExeleApi = (query) => {
    return request({
        url: '/video_process',
        method: 'post',
        data:query
    })
}

/**
 * 视频详情
 * @param query 
 * @returns 
 */
export const fetchVideoApi = (query) => {
    return request({
        url: '/video_detail',
        method: 'get',
        params:query
    })
}

/**
 * 查询任务
 * @param {*} user_id 
 * @param {*} article_id 
 * @returns 
 */
 export const queryTaskApi = (user_id, article_id) => {
    return request({
        url:'/video_task',
        method: 'get',
        params:{
            user_id,
            article_id
        }
    })
}


/**
 * 删除任务
 * @param {*} user_id 
 * @param {*} article_id 
 * @returns 
 */
 export const delTaskItemApi = (user_id,article_id) => {
    return request({
        url:'/video_task_del',
        method:'get',
        params:{
            user_id,
            article_id
        }
    })
}

/**
 * 获取失视频处理任务列表
 * @param {} page 
 * @param {*} page_size 
 * @param {*} user_id 
 * @returns 
 */
 export const getTaskListApi = (page, page_size, user_id) => {
    return request({
        url:'/video_list',
        method:'get',
        params:{
            page,
            page_size,
            user_id
        }
    })
}

/**
 * 注册
 * @param {*} email 
 * @param {*} password 
 * @param {*} code 
 * @returns 
 */
export const logonApi = (email, password, code) => {
    return request({
        url:'/Register',
        method:'post',
        data:{
            email,
            password,
            code
        }
    })
}

/**
 * 登录
 * @param {*} email 
 * @param {*} password 
 * @returns 
 */
export const loginApi = (email, password) => {
    return request({
        url:'/Login',
        method:'post',
        data:{
            email,
            password,
        }
    })
}

/**
 * 获取验证码
 * @param {*} email 
 * @returns 
 */
export const getCodeApi = (email) => {
    return request({
        url:'/Register',
        method:'get',
        params:{
            email
        }
    })
}

/**
 * 重新总结
 * @param {*} task_id 
 * @param {*} refine_language 
 * @returns 
 */
export const againSummaryApi = (data) => {
    return request({
        url:'video_summarize',
        method:'get',
        params:data
    })
}