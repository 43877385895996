<template>
    <div class="main_page">
        <comHeader @changevisible="changelogin" :loginVisible.sync="loginVisible">
            <template #btn>
                <button class="text_btn" @click="videoDialog = true">
                    AI视频生成
                </button>
            </template>
        </comHeader>
        <div class="main_content">
            <div class="main_area">
                <div class="main_title">
                    <h1 class="word_art">音频、视频</h1>
                    <h2>AI解析助手一键总结</h2>
                </div>
                <div class="txt_info">
                    <!-- /douyin/爱奇艺/腾讯视频/会议/讲座/论坛等 -->
                    在输入框内粘贴输入bilibili、douyin等音视频链接或上传本地文件进行AI解析总结
                </div>
                <div class="main_fun">
                    <!-- /douyin/爱奇艺/腾讯视频/会议/讲座/论坛等 -->
                    <el-input v-model="WebInput" placeholder="请输入bilibili、douyin等音视频链接" />
                    <el-button type="primary" class="import_btn"
                        style="margin-left: 10px;height: 40px;width: 180px;font-size: 16px;"
                        @click="uploadVideo">一键总结</el-button>
                </div>
                <div class="more_fun">
                    — 您还可以 —
                </div>
                <div class="summarize_btn">
                    <el-button type="primary" @click="uploadDialog = true" class="import_btn">批量导入</el-button>
                    <el-upload v-if="token" v-model="fileList" class="upload-demo" ref="upload" :on-change="on_change"
                        :on-remove="removeFile" :auto-upload="false" action :file-list="fileList" :show-file-list="true"
                        accept="video/*,audio/*">
                        <el-button type="primary" class="import_btn">本地上传</el-button>
                    </el-upload>
                    <el-button v-else type="primary" class="import_btn" @click="loginFirst">本地上传</el-button>
                </div>

                <div class="function_box_info">
                    <img src="../assets/Xnip2024-03-19_13-41-04(2).png" alt="">
                </div>
                <div class="fun_detail_info">
                    <div class="top_title">利用人工智能解析、查看视频、音频，快速挖掘与了解内容</div>
                    <div class="fun_info">
                        <div class="item_info">
                            <div class="img_box">
                                <img src="../assets/u22.png" alt="">
                            </div>
                            <div class="question_txt">为什么要进行音视频解析？</div>
                            <div class="problem_description">
                                语义解析模型则能够迅速获取、整理信息，实现视频交流，有效提升信息获取效率，优化工作质量。
                            </div>
                        </div>
                        <div class="item_info">
                            <div class="img_box">
                                <img src="../assets/u23.png" alt="">
                            </div>
                            <div class="question_txt">我能用这个工具做什么？</div>
                            <div class="problem_description">
                                借助视频、语音解析，你能轻松从音/视频中快速提炼出有用信息，更容易理解内容，获取相关信息。
                            </div>
                        </div>
                        <div class="item_info">
                            <div class="img_box">
                                <img src="../assets/u24.png" alt="">
                            </div>
                            <div class="question_txt">它的原理是什么？</div>
                            <div class="problem_description">
                                自动识别音视频中的语音文本。当您提出问题时，它会从这些文本中提取您所需的信息，并精炼出核心内容和亮点。
                            </div>
                        </div>
                    </div>
                </div>


                <div class="introduction_usage">
                    <h1>各类音、视频资源</h1>
                    <div class="introduction_info">Chat音视频助手支持会议视频、论坛讲座、语音、在线视频等音视频资源的解析总结、问答对话！</div>
                    <ul>
                        <li>
                            <div class="img_box">
                                <img src="../assets/会议费.png" alt="">
                            </div>
                            <div class="txt_box">
                                <h4>会议论坛深度解析</h4>
                                <div class="answer_">准确地对会议视频进行深度分析和处理</div>
                            </div>
                        </li>
                        <li>
                            <div class="img_box">
                                <img src="../assets/视频.png" alt="">
                            </div>
                            <div class="txt_box">
                                <h4>在线视频一键总结</h4>
                                <div class="answer_">自动生成在线视频摘要、观点和重要内容</div>
                            </div>
                        </li>
                        <li>
                            <div class="img_box">
                                <img src="../assets/音频.png" alt="">
                            </div>
                            <div class="txt_box">
                                <h4>音视频内容分析挖掘</h4>
                                <div class="answer_">对音频视频材料进行全面分析和探索</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <comFooter></comFooter>

        <el-dialog :title="typeText" :visible.sync="dialogVisible" width="550px">
            <!-- 视频语言选组框 -->
            <div v-if="showType === 'language'">
                <div style="margin:auto;margin-bottom:25px;width: 85%;font-size: 16px;">
                    <div style="margin-bottom:15px">
                        视频语种：
                        <el-radio v-model="videoLanguage" label="1" v-removeAriaHidden>中文</el-radio>
                        <el-radio v-model="videoLanguage" label="2" v-removeAriaHidden>英文</el-radio>
                    </div>
                    <div style="margin-bottom:15px" v-if="videoLanguage === '2'">
                        是否双语翻译：
                        
                        <el-radio v-model="bilingual" label="1" v-removeAriaHidden>是</el-radio>
                        <el-radio v-model="bilingual" label="2" v-removeAriaHidden>否</el-radio>
                    </div>
                    <div v-if="bilingual === '1' && videoLanguage === '2'">
                        待 翻 译 语 言：
                        <el-radio v-model="radioLanguage" label="ZH/zh-CN-YunyangNeural" v-removeAriaHidden>中文（男声）</el-radio>
                        <el-radio v-model="radioLanguage" label="ZH/zh-CN-XiaoxiaoNeural" v-removeAriaHidden>中文（女声）</el-radio>
                        <!-- <el-radio v-model="radioLanguage" label="EN-US/zh-CN-YunyangNeural">英文（男声）</el-radio> -->
                        <div style="margin-top: 10px;padding-left: 120px;">
                            <!-- <el-radio v-model="radioLanguage" label="EN-US/zh-CN-XiaoxiaoNeural">英文（女声）</el-radio> -->
                        </div>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer" style="text-align: center;">
                    <el-button v-if="uploadLength == 1" style="padding: 8px 20px;width:160px;" @click="submitLanguage">确
                        认</el-button>
                    <el-button v-else style="padding: 8px 20px;width:160px;" @click="submitMore">确
                        认</el-button>
                </div>

            </div>
            <!-- 视频文件上传进度 -->
            <div v-else-if="showType === 'upload'">
                <div class="text_description">视频正在上传，请稍后...</div>
                <div class="upload_progress">
                    <el-progress style="width:520px" :percentage="+percent"></el-progress>
                </div>
            </div>
            <!-- 视频解析进度 -->
            <div v-else>
                <div class="text_description">视频解析正在进行，已存入
                    <span style="color:#3C6ECE;font-weight:600;cursor: pointer;font-size:16px"
                        @click="goMine">个人中心-{{ list_name }}</span>，
                    提炼完成后可以直接查看并下载
                </div>
                <el-progress v-if="list_name === '总结记录'" style="margin-bottom: 20px;"
                    :percentage="taskSchedule == '3' ? 100 : taskSchedule == '1' ? 30 : taskSchedule == '2' ? 65 : 0"></el-progress>
                <el-progress v-else style="margin-bottom: 20px;"
                    :percentage="progress"></el-progress>
                <div slot="footer" class="dialog-footer" style="text-align: center;">
                    <el-button :key="key" v-if="list_name === '总结记录'" style="padding: 8px 20px;width:120px;margin-right:20px" :disabled="taskSchedule != '3'" @click="view">查 看</el-button>
                    <el-button type="primary"
                        style="background:#3C6ECE;border-color:#3C6ECE;padding: 8px 20px;width:120px"
                        @click="dialogVisible = false">稍后观看</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- 批量导入弹窗 -->
        <el-dialog title="批量上传" :visible.sync="uploadDialog" width="600px">
            <div class="way_box">
                <p class="way_tit">
                    方式一：请选择您要导入的EXCEL文件拖到此处进行批量解析
                    <el-popover placement="bottom" width="680" trigger="hover">
                        <div>
                            <img src="../assets/excel.png" width="650px">
                        </div>
                        <el-button slot="reference" class="example_btn">示例</el-button>
                    </el-popover>
                </p>
                <!--  -->
                <el-upload ref="xlsxUplaod" class="upload-demo" drag :action="uploadUrl" name="file" accept=".xlsx"
                    :on-success="uploadSuccess" :auto-upload="false" :on-change="beforeUpload"
                    :show-file-list="showList">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
                </el-upload>
            </div>
            <div>
                <p class="way_tit">方式二：链接粘贴至此处进行多任务上传，每行使用";"隔开</p>
                <textarea v-model="batchList" id="" cols="30" rows="10"></textarea>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" style="width: 180px;padding: 8px 0;background-color: #1C3C86;border: none;"
                    @click="batchUpload">一 键 总 结</el-button>
            </span>
        </el-dialog>
        <el-dialog title="" :visible.sync="submitDialog" width="500px" @close="cancelUpload">
            <div style="width: 100%;text-align: center;">确认上传该文件并进行批量解析!</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" style="background-color: #fff;border: none;color: #333;"
                    @click="cancelUpload">取消</el-button>
                <el-button type="primary" style="background-color: #1C3C86;border: none;"
                    @click="uploadSuccess">确认</el-button>
            </span>
        </el-dialog>
        <!-- ai视频生成 -->
        <el-dialog title="AI视频生成器" :visible.sync="videoDialog" width="800px">
            <videoMake @madeProcess="madeProcess" @loginState="loginState"></videoMake>
        </el-dialog>
    </div>
</template>

<script>
import comHeader from '@/components/ComHeader.vue'
import comFooter from '@/components/ComFooter.vue'
import videoMake from '@/components/videoMake.vue'
import { getVideoProcessApi, queryTaskApi, uploadExeleApi } from '@/api/user.js'
import axios from 'axios'
import { getProcessApi } from '../api/textTo'
export default {
    components: {
        comHeader,
        comFooter,
        videoMake
    },
    data() {
        return {
            loginVisible: false,
            videoUrl: '',
            webList: [],
            WebInput: '',
            fileList: [],
            articleId: '',
            isFetching: false,
            taskSchedule: '0',
            dialogVisible: false,
            timer: null,
            user_id: '',
            percent: 0,
            requestList: [],
            radioLanguage: 'ZH/zh-CN-YunyangNeural',
            bilingual: '2',
            videoLanguage: '2',
            showType: 'language',
            typeText: '翻译语言',
            videoType: '', //link:链接，file:文件
            token: '',
            // 批量上传
            uploadDialog: false,
            batchList: '',
            uploadUrl: window.location.origin + "/api/video_process",
            // uploadUrl: 'http://192.168.66.18:8012/api/video_process',
            // uploadUrl:'http://chatmedia.newacademic.net/api/video_process',
            submitDialog: false,
            file: '',
            showList: true,
            uploadLength: 1,
            list_name: '总结记录',
            videoDialog: false,
            makeId: '',
            progress: 0,
            key:0,
        }
    },
    beforeDestroy() {
        clearTimeout(this.timer)   //结束定时器，
    },
    created() {
        // 从路由获取token
        const chatToken = this.$route.query.token;
        const userId = this.$route.query.userId;
        if (chatToken && userId) {
            // 存储token 并获取用户信息
            localStorage.setItem('conf_token', chatToken);
            localStorage.setItem('conf_id', userId);
        }

        this.user_id = localStorage.getItem('conf_id')
        this.token = localStorage.getItem('conf_token')
    },
    methods: {
        madeProcess(taskid) {
            this.videoDialog = false
            this.dialogVisible = true
            this.typeText = '视频生成进度'
            this.showType = 'schedule'
            this.list_name = '生成记录'
            this.makeId = taskid
            this.getProcess()
        },
        loginState(bol) {
            this.loginVisible = bol
        },
        getProcess() {
            getProcessApi(this.makeId).then(res => {
                if (res.data.code === 200) {
                    console.log(res.data)
                    if (res.data.data.state === 4) {
                        this.progress = res.data.data.progress;
                        console.log(this.progress)
                        this.timer = setTimeout(() => {
                            this.getProcess()
                        }, 10000)
                    } else {
                        clearTimeout(this.timer)
                        this.makeId = ''
                        this.taskSchedule = '3'
                    }
                }
            })
        },
        changelogin(bol, token) {
            this.loginVisible = bol
            this.token = token
        },
        on_change(file, fileList) {
            const that = this
            this.dialogVisible = true
            this.typeText = '翻译语言'
            this.showType = 'language'
            this.videoType = 'file'

            fileList.forEach((item, index) => {
                if (item.raw.size == 0 && item.raw.type == "") {
                    fileList.splice(index, 1);
                    this.$message.error("请勿上传空文件或文件夹");
                }
            });
            this.$nextTick(() => {
                that.fileList = fileList;
            });
        },
        handlePreview(file) {
            console.log(file)
        },
        beforeRemove(file, fileList) {
            console.log(file, fileList)
        },
        handleRemove(file, fileList) {
            console.log(file, fileList)
        },
        handleExceed(files, fileList) {
            console.log(files, fileList)
        },
        removeFile() {
            this.fileList = []
        },
        goDetail() {
            router.push('/home')
            localStorage.removeItem('article_id')
        },
        uploadVideo() {
            if (!localStorage.getItem('conf_token')) {
                this.$message.warning('请先登录账号')
                this.loginVisible = true
                return
            }
            // 判断是否是youtube的视频
            if (this.WebInput.includes('youtube.com') || this.WebInput.includes('youtu.be')) {
                this.$message.warning('暂不支持youtube视频解析')
                return
            }
            if (this.WebInput) {
                // 展示弹窗
                this.dialogVisible = true
                this.typeText = '翻译语言'
                // 弹窗展示选择语言部分
                this.showType = 'language'
                this.videoType = 'link'
            } else {
                this.$message.warning('请输入视频地址')
            }
        },
        loginFirst() {
            this.$message.warning('请先登录账号')
            this.loginVisible = true
        },
        // 确认语言选择
        submitLanguage() {
            if (this.videoType == 'link') {
                getVideoProcessApi({
                    user_id: localStorage.getItem('conf_id'),
                    video_url: this.WebInput,
                    tran_language: this.bilingual === '1' ? this.radioLanguage.split('/')[0] : '',
                    video_language: this.videoLanguage,
                    refine_language: 1,
                    role: this.bilingual === '1' ? this.radioLanguage.split('/')[1] : ''
                }).then(res => {
                    console.log(res)
                    if (res.data.code === 200) {
                        if (res.data.data) {
                            this.articleId = res.data.data
                        } else {
                            this.$message.warning(res.data.message)
                        }
                        this.WebInput = ''
                        // 弹窗显示任务处理进度
                        this.typeText = '视频处理进度'
                        this.showType = 'schedule'
                        this.list_name = '总结记录'
                        this.key++
                        this.queryTask()
                    } else if (res.data.code === 400) {
                        this.$message.error(res.data.message)
                        this.loginVisible = true
                    }
                    else {
                        this.$message.error(res.data.message)
                    }
                })
            } else {
                this.showType = 'upload'
                this.typeText = '视频上传进度'
                this.Upload(this.fileList[0].raw, this.fileList[0].name)
            }
        },
        // 获取任务进度
        async queryTask() {
            // 调接口
            if (this.isFetching) return;

            this.isFetching = true;
            try {
                const res = await queryTaskApi(localStorage.getItem('conf_id'), this.articleId)
                if (res.data.code == 200) {
                    if (res.data.data == '3') {
                        clearTimeout(this.timer)
                        this.taskSchedule = res.data.data
                        // this.viewDetail()
                    } else {
                        this.taskSchedule = res.data.data
                        if (this.dialogVisible) {
                            if (+res.data.data < 0) {
                                clearTimeout(this.timer)
                                // 视频处理失败后 移出id
                                this.$message.error('视频处理失败')
                                this.dialogVisible = false
                            } else {
                                this.timer = setTimeout(() => {
                                    this.queryTask()
                                }, 20000)
                            }
                        }

                    }
                } else if (res.data.code === 400) {
                    this.$message.error(res.data.message)
                    this.loginVisible = true
                }
            } catch (error) {
                // 处理错误
                console.log(error);
            } finally {
                this.isFetching = false;
            }

        },
        // 去个人中心
        goMine() {
            let listype = this.list_name === '总结记录' ? 1 : 2
            this.$router.push({
                path: '/record',
                query: {
                    listype: listype
                }
            })
        },
        view() {
            this.$router.push({
                path: '/home',
                query: {
                    id: this.articleId
                }
            })
            localStorage.setItem('radio_conf', '1')
        },

        async Upload(file, name) {
            this.loadingStatus = true;

            //2.分片
            let ChunkSize = 5 * 1024 * 1024;
            const chunkList = this.createFileChunk(file, ChunkSize); //切割分片函数
            this.requestList = chunkList.map(({ file }, index) => ({
                file,
                index,
                partSize: file.size, //当前片大小
                // partNumber: index + 1, //当前片数
                partNumber: index, //当前片数
                totalPart: chunkList.length, //总片数
                uploadId: this.uploadId, //分片上传id
                filename: name, //分片上传id
            }));
            await this.uploadChunks(); // 执行上传切片的操作
        },
        createFileChunk(file, size) {
            const fileChunkList = [];
            let cur = 0;
            while (cur < file.size) {
                fileChunkList.push({ file: file.slice(cur, cur + size), progress: 0 });
                console.log(file.slice(cur, cur + size));
                cur += size;
            }
            return fileChunkList;
        },
        async uploadChunks(uploadedList = []) {
            const requestList = this.requestList
                .filter((chunk) => !uploadedList.includes(chunk))
                .map(
                    ({
                        file,
                        partNumber,
                        totalPart,
                        index,
                        filename,
                    }) => {
                        const formData = new FormData();
                        formData.append("file", file);
                        // formData.append("partSize", partSize);
                        formData.append("chunk", partNumber);
                        formData.append("chunks", totalPart);
                        formData.append("filename", filename);
                        formData.append("user_id", localStorage.getItem('conf_id'));
                        formData.append("refine_language", 1);
                        formData.append("tran_language", this.bilingual === '1' ? this.radioLanguage.split('/')[0] : 'zh-CN-YunyangNeural');
                        formData.append("video_language", this.videoLanguage);
                        formData.append("role", this.bilingual === '1' ? this.radioLanguage.split('/')[1] : 'zh-CN-YunyangNeural');
                        return { formData, index };
                    }
                );
            await this.sendRequest(requestList, 4);

        },
        async sendRequest(forms, max) {
            this.uploading = true;
            const Status = { wait: 1, error: 2, done: 3, fail: 4 };
            return new Promise((resolve, reject) => {
                const len = forms.length;
                let counter = 0;
                const retryArr = [];
                forms.forEach((item) => {
                    item.status = Status.wait;
                    item.progress = item.progress || 0; // 添加这一行来确保 progress 属性存在
                });
                const start = async () => {
                    while (counter < len && max > 0) {
                        max--; // 占用通道
                        let idx = forms.findIndex(
                            (v) => v.status == Status.wait || v.status == Status.error
                        );
                        if (idx == -1) {
                            return reject();
                        }
                        let { formData, index } = forms[idx];
                        try {
                            const url = window.location.origin + "/upload";
                            // const url = 'http://chatmedia.newacademic.net/upload'
                            // const url = 'http://192.168.45.127:8080/upload'
                            const res = await axios.post(
                                url,
                                formData
                            );
                            if (res.status === 200) {
                                forms[idx].status = Status.done;
                                forms[idx].progress = 100;
                                max++; // 释放通道
                                counter++;
                                forms.forEach((form) => {
                                });

                                const totalProgress = forms.reduce((total, form) => {
                                    return total + (form.progress || 0); // 添加这一行来确保 progress 属性存在
                                }, 0);

                                this.percent = (totalProgress / forms.length).toFixed(2);
                                if (res.data.data) {
                                    this.dialogVisible = false
                                    this.$message.success('上传成功')
                                    setTimeout(() => {
                                        this.dialogVisible = true
                                        this.typeText = '视频处理进度'
                                        this.showType = 'schedule'
                                        this.articleId = res.data.data
                                        this.fileList = []
                                        this.queryTask()

                                        console.log(this.fileList);
                                    }, 500);
                                } else if (res.data.code === 500 || res.data.code === 413) {
                                    this.$message.error(res.data.message)
                                    this.dialogVisible = false
                                    this.fileList = []
                                    return
                                }
                                // if (counter == len) {
                                // this.uploading = false;
                                // e.upload_text = res.data.data.url;
                                // if (!this.$refs.upload) {
                                //     var list;
                                //     if (localStorage.getItem("tasklist"))
                                //     list = JSON.parse(localStorage.getItem("tasklist"));
                                //     else {
                                //     list = [];
                                //     }
                                //     // 视为当前组件以无父组件 存入本地
                                //     var tasklist = {
                                //     child: {
                                //         task: task,
                                //         group_name: this.group_name,
                                //     },
                                //     upload_status: {
                                //         upload_status: 3,
                                //         task_id: e.task_id,
                                //         group_name: this.group_name,
                                //     },
                                //     chclick: {
                                //         chclick: true,
                                //         e: e,
                                //         i: i,
                                //         t: t,
                                //     },
                                //     };
                                //     // 查询列表中是否有 tesk_id 相同的数据 ,有则替换
                                //     var index_ = list.findIndex((item) => {
                                //     return item.upload_status.task_id == e.task_id;
                                //     });
                                //     if (index_ != -1) {
                                //     list.splice(index_, 1, tasklist);
                                //     } else {
                                //     list.push(tasklist);
                                //     }
                                //     // console.log(list);
                                //     localStorage.setItem("tasklist", JSON.stringify(list));
                                // }
                                // // this.$emit("child", task, this.group_name);
                                // this.$emit("upload_status", 3, e.task_id, this.group_name); // 上传完成
                                // this.$emit("chclick", true, e, i, t);
                                // that.fileLists = [];
                                // // console.log(task.fileName);
                                // console.log("成功", e);
                                // return res.requestUrls;
                                // resolve();
                                // }
                            } else {
                                forms[idx].status = Status.error;
                                if (typeof retryArr[index] !== "number") {
                                    this.$message.error(`第 ${index} 个块上传失败，系统准备重试`);
                                    retryArr[index] = 0;
                                }
                                // 次数累加
                                retryArr[index]++;
                                // 一个请求报错3次的
                                if (retryArr[index] > 3) {
                                    this.$message.error(`第 ${index} 个块重试多次无效，放弃上传`);
                                    forms[idx].status = Status.fail;
                                }
                                max++; // 释放通道
                                // 关闭弹窗
                                this.dialogVisible = false
                                this.fileList = []
                                return
                            }
                        } catch (error) {
                            console.log(error, retryArr);
                            forms[idx].status = Status.error;
                            if (typeof retryArr[index] !== "number") {
                                this.$message.error(`第 ${index} 个块上传失败，系统准备重试`);
                                retryArr[index] = 0;
                            }
                            // 次数累加
                            retryArr[index]++;
                            // 一个请求报错3次的
                            if (retryArr[index] > 3) {
                                this.$message.error(`第 ${index} 个块重试多次无效，放弃上传`);
                                forms[idx].status = Status.fail;
                            }
                            max++; // 释放通道
                            // 关闭弹窗
                            this.dialogVisible = false
                            this.fileList = []
                            return
                        }
                    }
                };
                start();
            });
        },
        // 批量上传
        // excel
        beforeUpload(file, fileList) {
            if (!localStorage.getItem('conf_token')) {
                this.$message.warning('请先登录账号')
                this.loginVisible = true
                return
            } else {
                if (fileList.length > 0) {
                    this.submitDialog = true
                    this.file = file.raw
                }
            }
        },
        async uploadSuccess() {
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('user_id', this.user_id)
            const res = await uploadExeleApi(formData)
            if (res.data.code === 200) {
                this.dialogVisible = false
                this.submitDialog = false
                this.$message.success('上传成功')
                this.$refs.xlsxUplaod.clearFiles()
                // 跳转到个人中心
                setTimeout(() => {
                    this.$router.push('/record')
                }, 500);
            } else if (res.data.code === 400) {
                this.$message.error(res.data.message)
                this.loginVisible = true
            } else {
                // this.$message.error(res.data.message)
                this.$refs.xlsxUplaod.clearFiles()
                this.submitDialog = false
            }
        },
        cancelUpload() {
            this.submitDialog = false
            this.$refs.xlsxUplaod.clearFiles()
        },
        uploadTask(task) {
            return new Promise((resolve, reject) => {
                // 模拟上传任务
                getVideoProcessApi({
                    user_id: localStorage.getItem('conf_id'),
                    video_url: task,
                    tran_language: this.bilingual === '1' ? this.radioLanguage.split('/')[0] : '',
                    video_language: this.videoLanguage,
                    refine_language: 1,
                    role: this.bilingual === '1' ? this.radioLanguage.split('/')[1] : ''
                }).then(res => {
                    if (res.data.code === 400) {
                        this.$message.error(res.data.message)
                        this.loginVisible = true
                    }
                })
                setTimeout(() => {
                    // 假设任务上传成功
                    this.goMine()
                    resolve(`Task ${task} uploaded successfully`);
                }, 500);
            });
        },
        async submitMore() {
            let list = this.batchList.split(/[;；]/)
            try {
                const uploadPromises = list.map(task => this.uploadTask(task));
                // 使用 Promise.all 等待所有任务完成
                const results = await Promise.all(uploadPromises);
                // 所有任务上传成功后执行的操作
                console.log(results); // 打印每个任务的上传结果
            } catch (error) {
                console.error("Error uploading tasks:", error);
            }

        },
        // 文本
        batchUpload() {
            if (!localStorage.getItem('conf_token')) {
                this.$message.warning('请先登录账号')
                this.loginVisible = true
                return
            }
            // 循环batchList列表，判断是否有YouTube的视频
            let list = this.batchList.split(/[;；]/)
            for (let i = 0; i < list.length; i++) {
                if (list[i].includes('youtube.com')) {
                    this.$message.warning('暂不支持youtube视频解析')
                    return
                }
            }
            if (this.batchList) {
                // 选择是否双语翻译
                this.typeText = '翻译语言'
                // 将this.batchList用;分割成数组 ；不区分中英文
                let list = this.batchList.split(/[;；]/)
                this.uploadLength = list.length
                this.dialogVisible = true
                showType.value = 'language'
            } else {
                this.$message.warning('请输入视频地址')
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.main_page {
    position: relative;
    .text_btn{
        border: 1px solid #4685F9;
        color: #4685F9;
        margin-left: 20px;
        background-color: transparent;
        border-radius: 4px;
        padding: 8px 15px;
    }
    .main_content {
        display: flex;
        justify-content: space-between;
        min-height: 100vh;
        background: url('../assets/渐变背景.webp') no-repeat;
        background-color: #000;

        background-size: 100% 30%;
        color: #ccc;

        .main_area {
            width: 80%;
            min-width: 900px;
            margin: 0 auto;
            padding: 30px 0;

            .back_img_top {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 0;
            }

            .analysis_btn {
                width: 220px;
                margin: 10px auto;
                padding: 10px 0;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top: 1px solid #1C3C86;
                border-bottom: 1px solid #1C3C86;
                border-radius: 5px;

            }

            .main_title {
                display: flex;
                width: 100%;
                margin-top: 100px;
                text-align: center;
                align-items: center;
                justify-content: center;

                .word_art {
                    background-image: linear-gradient(to bottom, #FFC400, #fff);
                    color: transparent;
                    -webkit-background-clip: text;
                }

                h1 {
                    font-size: 72px;
                    text-align: center;
                    margin-right: 15px;
                }

                h2 {
                    font-size: 55px;
                }
            }

            .txt_info {
                background-size: 100% 100%;
                font-size: 24px;
                width: 85%;
                text-align: center;
                line-height: 40px;
                margin: 60px auto;
            }

            .main_fun {
                width: 90%;
                margin: 40px auto;
                margin-bottom: 60px;
                display: flex;
                align-items: center;

                ::v-deep .el-input__inner {
                    --el-input-inner-height: 40px;
                    line-height: 40px;
                }

                ::v-deep .el-input__wrapper {
                    background-color: #1D1B1C;
                    box-shadow: none;
                }

                ::v-deep .el-input__wrapper.is-focus {
                    box-shadow: 0 0 0 1px #1C3C86 inset;
                }

                span {
                    margin: 0 10px;
                    font-size: 14px;
                    line-height: 30px;
                }

                .import_btn {
                    width: 100px;
                    height: 30px;
                    background-color: #1C3C86;
                    border: 1px solid #1C3C86;
                    border-radius: 3px;
                    text-align: center;
                    line-height: 0px;
                    color: #fff;
                    cursor: pointer;
                }
            }

            .function_box_info {
                margin: 100px auto;
                margin-bottom: 220px;
                width: 70%;
                padding: 20px;
                border-radius: 8px;
                background-color: #1C3C86;

                img {
                    width: 100%;
                }
            }

            .same {
                display: flex;
                margin-bottom: 220px;

                .bottom_img {
                    width: 100%;
                    // height: 446px;
                    position: relative;

                    img {
                        width: 100%;
                        // height: 446px
                    }

                    .back_img {
                        position: absolute;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 446px;
                        background-color: rgba($color: #000000, $alpha: .8);
                    }
                }


                .middle_img {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    // transform: translate(-50%, -50%);
                    img {
                        height: 260px;
                        width: 116px;
                    }
                }

                h3 {
                    font-size: 34px;
                    margin-bottom: 15px;
                    color: #4534BB;
                }

                .interpretative_statement {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            .more_fun {
                text-align: center;
                margin-bottom: 25px;
            }

            .summarize_btn {
                display: flex;
                justify-content: center;
                border-radius: 5px;
                text-align: center;
                color: #fff;
                margin: 0 auto;
                cursor: pointer;

                button {
                    width: 120px;
                    font-size: 13px;
                    margin: 0 10px;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 10px;
                    background-color: #163572;
                    border: 1px solid #163572;
                }
            }

            .fun_detail_info {
                width: 85%;
                margin: auto;
                background-color: #1E1E1C;
                padding: 30px;

                .top_title {
                    width: 60%;
                    margin: auto;
                    font-size: 30px;
                    // font-weight: 700;
                    text-align: center;
                }

                .fun_info {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 40px;

                    .item_info {
                        width: 30%;
                        // padding: 20px 15px;
                    }

                    .img_box {
                        text-align: center;
                    }

                    .question_txt {
                        text-align: center;
                        font-size: 18px;
                        margin: 10px 0;
                        font-weight: 600;
                    }

                    .problem_description {
                        font-size: 16px;
                        line-height: 25px;
                    }
                }

            }

            .introduction_usage {
                padding: 20px;
                width: 100%;
                margin: 100px auto;
                margin-top: 220px;

                h1 {
                    text-align: center;
                    color: #fff;
                    font-size: 45px;
                }

                .introduction_info {
                    line-height: 40px;
                    text-align: center;
                    font-size: 20px;
                    margin: 20px 0;
                }

                ul {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 40px;

                    li {
                        width: 30%;
                        padding: 25px 15px;
                        border-radius: 5px;
                        background-color: #fff;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        color: #000;

                        .img_box {
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                            padding: 8px 10px;
                            border-radius: 40px;
                            margin-right: 12px;

                            img {
                                width: 30px;
                                vertical-align: middle;
                            }
                        }

                        h4 {
                            font-size: 18px;
                            margin-bottom: 10px;
                        }

                        .answer_ {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }


            }
        }
    }
}

.text_description {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 25px;
}

.upload_progress {
    ::v-deep .el-progress-bar {
        width: 470px !important;
    }
}

.way_tit {
    margin-bottom: 15px;

    .example_btn {
        padding: 0;
        background-color: transparent;
        border: none;
        font-size: 12px;
        color: #409eff;
        margin-left: 15px;
    }
}

.way_box {
    margin-bottom: 30px;

    ::v-deep .el-upload-list {
        position: static
    }
}

textarea {
    resize: vertical;
    width: 560px;
    background: transparent;
    border: 1px dashed #fff;
    border-radius: 8px;
    padding: 10px;
    color: #fff;
    font-size: 14px;
}

textarea:focus-visible {
    outline: none;
}

::v-deep .el-upload-dragger {
    background-color: transparent;
    width: 560px;
}

::v-deep .el-upload-dragger .el-upload__text {
    color: #fff;
}

::v-deep .el-upload-list {
    position: absolute;
}

::v-deep .el-dialog {
    border-radius: 10px;
    background-color: #011E33;
    color: #fff;
}

::v-deep .el-dialog__body {
    padding-bottom: 15px;
}

::v-deep .el-dialog__title {
    color: #fff !important;
}

::v-deep .el-dialog__body {
    color: #fff;
}

::v-deep .el-progress__text {
    color: #fff !important;
}

::v-deep .el-dialog__footer {
    display: flex;
    justify-content: center;
}

::v-deep .el-radio {
    color: #fff;
}

::v-deep .el-radio__label {
    font-size: 16px !important;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #156BD7;
    background-color: #156BD7;
}
</style>