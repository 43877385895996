import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router/index'
// 创建axios 实例
const service = axios.create({
    // baseURL: "http://192.168.66.9:7000/api/",
    // baseURL: window.location.origin +'/api/', 
    baseURL: 'https://chatlibrary.newacademic.net/api/',
    timeout: 50000, // 请求超时时间
})

service.interceptors.request.use((config) => {
    // config.headers.token = store.getCookie("Chat_token")
    if (config.url == 'chatcqu/') {
        if (config.method === 'post') {
            config.data = {
                ...config.data,
                t: Date.parse(new Date())
            }
        } else if (config.method === 'get') {
            config.params = {
                ...config.params,
                t: Date.parse(new Date())
            }
        }
    }
    return config
}, (err) => {
    return Promise.reject(err)
})

service.interceptors.response.use((res) => {
    const response = res.data
    if (response.code == 200) {
        return res
    } else if(response.code == 0){
        return res.data
    }else if (res.data.data) {
        return res.data
    } else {
        if (res.data.msg) {
            Message.error({
                message: res.data.msg,
                type: 'error'
            })
        }
        if (res.data.code === 400) {
            store.clearCookie("Chat_token");
            localStorage.clear()
            sessionStorage.clear()
            router.push({
                path: '/'
            })
        }
        return res
    }
}, (err) => {
    return Promise.reject(err)
})

export default service