<template>
    <div class="main_page" @resize="dragControllerDiv">
        <comHeader></comHeader>
        <div class="main_content">
            <div class="main_right">
                <div class="left">
                    <div class="video_realtive_box">
                        <div class="video_box">
                            <div class="audio_box" v-if="fileType === 'audio'">
                                <audioPlayer :audioUrl="officialVideoUrl" class="audio_player" ref="audioPlayer">
                                </audioPlayer>
                                <div></div>
                            </div>
                            <div class="l_viedo" v-else>
                                <xplayer ref="videoPlayer" :bigTime="bigTime" :url="officialVideoUrl"
                                    :subtitleTracks="subtitleTracks" @changeminiTime="changeminiTime"
                                    @downVideo="downVideo">
                                </xplayer>
                            </div>
                        </div>
                        <div class="captions_box" :style="{height: fileType === 'audio' ? '208px !important' : ''}">
                            <div class="related_live">
                                <div class="captions_title">
                                    <h4>字幕转写</h4>
                                    <span title="下载字幕文件" @click="downCaptions">
                                        <img src="../assets/下载.png" alt="">
                                    </span>
                                </div>
                                <div class="all_list">
                                    <div class="subtitle">
                                        <ul>
                                            <li class="subtitleList" v-for="(item, index) in subtitlesList" :key="index"
                                                @click="chechTime(item.start_time)" :title="item.text">
                                                <span class="subtitle_time">{{ item.start_time }}</span>
                                                <span class="subtitle_content">{{ item.text }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mengcen"></div>
                    <div class="bottom_main_info">
                        <div class="top_fun_two">
                            <div class="main_top_nav">
                                <span :class="tabIndex == 1 ? 'active' : ''" @click="tabIndex = 1">摘要总结</span>
                                <span :class="tabIndex == 2 ? 'active' : ''" @click="tabEcharts">思维导图</span>
                                <span :class="tabIndex == 3 ? 'active' : ''" @click="tabIndex = 3">关键标签</span>
                            </div>
                            <div class="right_download_fun">
                                <el-radio v-model="radio" @input="changeRadio" label="1">精简</el-radio>
                                <el-radio v-model="radio" @input="changeRadio" label="2">详细</el-radio>
                            </div>
                        </div>

                        <div class="bottom_detail">

                            <!-- 思维导图 -->
                            <div v-show="tabIndex == 2" class="echarts_outBox same_box">
                                <div class="echarts_box">
                                    <div class="mind_mapping"></div>
                                </div>
                            </div>
                            <!-- 关键标签 -->
                            <div v-if="tabIndex == 3" class="same_box">
                                <div class="keyword_tip">
                                    <p class="item_title">关键词标签：</p>
                                    <span v-for="(item, index) in keywordList" :key="index">{{ item }}</span>
                                </div>
                            </div>
                            <!-- 摘要总结 -->
                            <div id="allbox" style="position: absolute;left: 0;bottom: 0;z-index: -1;opacity: 0;"></div>
                            <div v-show="tabIndex == 1 || showBox" :style="{ 'marginTop': showBox ? '500px' : '' }"
                                class="same_box" id="export" style="position: relative;">
                                <div class="introduce" id="title">
                                    <p style="width:100px" class="item_title">视频标题：</p>
                                    <p class="count_in">{{ video_title }}</p>
                                </div>
                                <div class="introduce" id="abstract">
                                    <p style="width:100px" class="item_title">视频摘要：</p>
                                    <p class="count_in">{{ video_abstract }}</p>
                                </div>
                                <div class="introduce" id="brightSpot">
                                    <p style="width:100px" class="item_title">亮点总结：</p>
                                    <div class="count_in">
                                        <p v-for="(item, index) in videoBrightSpot" id="spotxt" :key="index"
                                            v-html="item">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right_download_fun" v-if="tabIndex == 1">
                            <span class="down_fun" @click="downFile">
                                <img src="../assets/文件下载.png" width="20px" style="vertical-align: middle;">
                                下载
                            </span>
                            <el-select v-model="downType" placeholder="请选择" style="width: 80px">
                                <el-option label="txt" value="txt"></el-option>
                                <el-option label="pdf" value="pdf"></el-option>
                            </el-select>
                            <span style="margin-left:8px">文件</span>
                        </div>
                    </div>
                </div>
                <!-- title="收缩侧边栏" -->
                <div class="resize" v-show="drawer"></div>
                <div class="mid" ref="mid" id="mid">
                    <div class="right_chat">
                        <div class="chat_top_box">
                            <img style="width:25px;" src="@/assets/detail/提问.png" alt="">
                            支持对音/视频内容进行提问，快速了解会议内容
                        </div>
                        <div class="fixed_issues" id="fixed_issues">
                            <div class="fixed_issues_tit" @click="showquestion(!questionshow)">
                                <span>
                                    <img style="width:35px;margin-left: 10px;" src="@/assets/detail/灯泡.png">
                                    AI将基于您添加的资源进行思考!
                                </span>
                                <span>
                                    <i v-if="!questionshow" class="el-icon-arrow-right"></i>
                                    <i v-else class="el-icon-arrow-down"></i>
                                </span>
                            </div>

                            <div class="default_question" v-if="questionshow">
                                <div class="default_question_item" v-for="(item, index) in defaultQuestion" :key="index"
                                    @click="default_question_click(item)">
                                    <span class="question_item">{{ item }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- 类似于chatpdf对话框 -->
                        <div ref="box" id="div" class="message_s box" :style="'height:' + message_sheight">
                            <div v-for="(item, index) in list" :key="index" style="width: 100%">
                                <div v-if="item.ask" class="ask_css">
                                    <div class="ask_img">
                                        <img src="@/assets/detail/user.png" alt="">
                                    </div>
                                    <div style="text-align: left;">
                                        <span style="text-align: justify; display: inline-block;line-height: 25px;">
                                            <span v-if="item.answer.includes('服务繁忙，请重试')">
                                                <i style="color: red; cursor: pointer" class="el-icon-warning"
                                                    @click="shortcut(item.ask)"></i> </span>
                                            <span class="font_duan" v-show="item.ask_duan">
                                                <span v-show="item.isimg == false">{{ item.ask_duan }}</span>
                                                <img style="width:100%;" v-show="item.isimg == true"
                                                    :src="item.ask_duan" alt="">
                                            </span>
                                            <span style="font-weight:600;">{{ item.ask }}</span>
                                        </span>

                                        <br />
                                        <span class="time__ask" style="">{{ item.ask_time }} </span>
                                    </div>
                                </div>
                                <div v-if="item.answer" class="answer_css"
                                    style="text-align: left; white-space: pre-wrap;width: 100%;">
                                    <div class="ask_img">
                                        <img style="width:25px;" src="@/assets/detail/提问.png" alt="">
                                    </div>
                                    <div style="width: 100%;color: #fff !important;">
                                        <div style="display: flex;word-break: break-word;width: 100%;">
                                            <div style="position: relative;width: 100%;">
                                                <div class="answer_css_">
                                                    <span v-if="item.answer.includes('服务繁忙，请重试')">服务繁忙，请重试</span>
                                                    <span v-else v-html="item.answer"></span>
                                                    <span v-if="showBlinkCursor && index + 1 == list.length"
                                                        class="blinkCursor">_</span>
                                                </div>
                                                <!-- <div v-if="item.stat !== ''" :class="item.answer.length > 15 ? 'pointer_s' : 'pointer_s_'
                                            ">
                                            <img src="@/assets/detail/播放 (6).png" alt="">
                                            <span @click="topage('items')">items</span>
                                        </div> -->
                                            </div>
                                        </div>
                                        <!-- <br /> -->
                                        <span v-if="item.answer_time" class="time__answer" style="">{{
        item.answer_time
    }}</span>
                                    </div>
                                </div>
                                <div v-if="item.answer && item.stop_show" @click="stopask" class="stopask">
                                    <img src="@/assets/detail/stopask.png" alt=""
                                        style="width: 20px;margin-right: 5px;">
                                    停止回答
                                </div>
                            </div>
                        </div>
                        <div class="message_bottom" id="message_bottom">
                            <div class="paragraphRe" v-show="paragraph">
                                <i class="el-icon-error" @click="removeparagraph"></i>
                            </div>
                            <textarea ref="input" v-show="paragraph" class="chat-input other" rows="1"
                                v-model="paragraph">
                                <i class="el-icon-circle-close"></i>
                            </textarea>

                            <div class="chat-input">
                                <div style="width:100% ;">
                                    <input class="input" type="text" v-model="newMessage" @keyup.enter="sendMessage"
                                        placeholder="Type a message..." />
                                    <!-- <button @click="sendMessage">Send</button> -->

                                    <!-- <i class="el-icon-s-promotion" @click="sendMessage"></i> -->
                                    <img @click="sendMessage" src="@/assets/发送.png" alt="" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mini_video" v-if="showMiniVideo">
            <video :src="officialVideoUrl" id="miniVideo"></video>
        </div>
        <el-dialog title="正在上传" :visible.sync="uploadDialog" width="580px" :close-on-click-modal="false">
            <div class="text_description">视频正在上传，请稍后...</div>
            <div class="upload_progress">
                <el-progress style="width:520px" :percentage="+percent"></el-progress>
            </div>
        </el-dialog>
        <comFooter></comFooter>
    </div>
</template>

<script>
import comHeader from '@/components/ComHeader.vue'
import comFooter from '@/components/ComFooter.vue'
import $ from "jquery";
import * as echarts from 'echarts';
import xplayer from '../components/videoBox.vue'
import { fetchVideoApi, getVideoProcessApi, queryTaskApi, againSummaryApi } from '@/api/user.js'
import audioPlayer from '../components/audio.vue'
import axios from 'axios'
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
    components: {
        comHeader,
        comFooter,
        xplayer,
        audioPlayer
    },
    data() {
        return {
            bigTime: 0, //当视频播放时间
            showMiniVideo: false, //是否显示小视频
            videoUrl: '',
            drawer: true,
            videoId: '',
            message_sheight: "calc(100vh - 604.92px)",
            showBlinkCursor: false,
            paragraph: '',
            questionshow: true,
            newMessage: '',
            officialVideoUrl: null, //视频链接
            levelValue: 1, //大纲层级
            mainValue: 1, //要点个数
            languageValue: 1, //选择输出语言
            video_abstract: '', //视频摘要 
            video_title: '', //视频标题
            tabIndex: 1, //tab切换
            defaultQuestion: [
                '这段会议视频的概要是什么？',
                '这段会议视频尝试解决什么具体问题？',
                '会议提出了哪些亮点观点？',
                '这段会议视频主要讨论的主题是什么？',
                '目前面临的主要挑战是什么？',
                '请对本段视频进行总结'
            ],
            liveList: [], //相关视频
            subtitleTracks: [
                { src: '', srclang: 'zh', label: '显示字幕' },
            ],
            list: [
                {
                    ask: "",
                    answer: "",
                    ask_time: "",
                    answer_time: "",
                    stat: "",
                    id: "",
                    stop_show: false, // 是否显示停止按钮
                    numbers: [],
                    isimg: false, // 是否是图片
                },
            ],
            subtitlesList: [
                {
                    start_time: '5:34',
                    text: 'nihao'
                }
            ],
            videoBrightSpot: [], //视频亮点
            keywordList: ['key', 'value'], //关键词
            mindMapList: {}, //思维导图
            start_time: 0, //开始时间
            end_time: 0, //结束时间    
            show_: false, //
            end_: 0,
            returnMessageAjax: null,
            fileList: [],
            downType: 'txt',
            article_id: '',
            newArticleId: '',
            taskSchedule: '',
            timer: null,
            urlTimer: null,
            showBox: false,
            user_id: '',
            fileType: 'video',
            uploadDialog: false,
            percent: 0,
            taskId: '',
            radio: '1',
            progressStatus: '',
            vttUrl: '', //vtt文件
            translateVttUrl: '', //翻译vtt文件
            typeOneVideo: '',
            typeTwoVideo: '',
        }
    },
    created() {
        this.user_id = localStorage.getItem('conf_id')
    },
    mounted() {
        // this.dragControllerDiv()
        this.article_id = this.$route.query.id
        if (localStorage.getItem('radio_conf') && localStorage.getItem('radio_conf') !== 'null') {
            this.radio = localStorage.getItem('radio_conf')
        } else {
            this.radio = '1'
        }
        this.fetchVideo()
        // 从本地获取article_id
        if (localStorage.getItem('article_id')) {
            this.newArticleId = localStorage.getItem('article_id')
            this.queryTask()
        } else {
            clearTimeout(this.timer)
        }

        // 初始化高度
        const video = document.querySelector('#videoJsCon')
        let captions = document.querySelector('.captions_box')
        if (video && captions) {
            captions.style.height = video.offsetHeight + 'px'
        }
        // 页面样式渲染完毕高度设置
        this.$nextTick(() => {
            let listBox = document.querySelector('.all_list')
            setTimeout(() => {
                const video_box = document.querySelector('.video-js')
                if (video_box) {
                    captions.style.height = video_box.offsetHeight + 'px'
                    listBox.style.height = (video_box.offsetHeight - 45) + 'px'
                }

            }, 500)
        })
        // 监听浏览器视口大小   
        window.onresize = () => {
            let captions = document.querySelector('.captions_box')
            let listBox = document.querySelector('.all_list')
            // setTimeout(() => {
            const video_box = document.querySelector('.video-js')
            if (video_box) {
                captions.style.height = video_box.offsetHeight + 'px'
                listBox.style.height = (video_box.offsetHeight - 45) + 'px'
            }
        }
        this.changeHeight()
    },
    beforeDestroy() {
        clearTimeout(this.timer)   //结束定时器，
        clearTimeout(this.urlTimer)   //结束定时器，
        localStorage.removeItem('article_id')
        // 清除监听
        window.onresize = null
    },
    watch: {
        // 监听路由变化
        $route(to, from) {
            console.log(to, from);
            this.article_id = this.$route.query.id
            this.fetchVideo()
            this.$refs.videoPlayer.reload()
        }
    },
    methods: {

        changeHeight() {
            // 选择目标元素
            var target = document.querySelector('.captions_box');

            // 创建一个 Mutation Observer 实例并指定回调函数
            var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                        // 当 style 属性变化时，可以进一步检查高度的变化
                        let listBox = document.querySelector('.all_list')
                        listBox.style.height = (target.clientHeight - 45) + 'px'
                    }
                });
            });

            // 配置 Mutation Observer 监视的内容
            var config = { attributes: true };

            // 启动 Mutation Observer 并传入目标节点和配置
            observer.observe(target, config);
        },
        changeminiTime(val) {
            const video = document.querySelector('#miniVideo')
            let isPaused
            if (video) {
                // 获取video当前的播放状态
                isPaused = video.paused
            }
            if (isPaused) {
                video.load();
                // 播放视频
                const play = video.play();
                // this.videoPlayer.currentTime(this.bigTime);
                play.then(() => {
                    video.currentTime = val
                }).catch((e) => {
                    // console.log('play error', e);
                });
            }
        },
        downVideo(type) {
            if (type === 1) {
                window.open(this.typeOneVideo, '_blank')
            } else {
                window.open(this.typeTwoVideo, '_blank')
            }
        },
        downVttFile(url, txt) {
            const title = txt === '' ? this.video_title : this.video_title + '(' + txt + ')'
            const xhr = new XMLHttpRequest();
            // 设置请求的 URL
            xhr.open('GET', url);
            // 设置响应类型为二进制数据
            xhr.responseType = 'blob';
            // 当请求完成时执行的回调函数
            xhr.onload = () => {
                // 创建一个隐藏的 <a> 标签
                const link = document.createElement('a');
                link.style.display = 'none';
                // 创建一个 Blob 对象，用于存储响应数据 type 为vtt格式文件
                const blob = new Blob([xhr.response], { type: 'text/vtt' });
                // 设置下载链接
                link.href = URL.createObjectURL(blob);
                // 设置下载文件的名称（可选）
                link.download = title + '.vtt';
                // 将 <a> 标签添加到页面中
                document.body.appendChild(link);
                // 触发点击事件进行下载
                link.click();
                // 移除 <a> 标签
                document.body.removeChild(link);
            };
            // 发送请求
            xhr.send();
        },
        downCaptions() {
            // 创建一个 XMLHttpRequest 对象
            if (this.vttUrl !== '') {
                this.downVttFile(this.vttUrl, '')
            }
            if (this.translateVttUrl !== '') {
                this.downVttFile(this.translateVttUrl, 'translate')
            }
            if (this.vttUrl === '') {
                this.$message({
                    message: '暂无字幕文件',
                    type: 'warning'
                })
            }
        },
        changeRadio(val) {
            console.log(this.radio)
            if (this.progressStatus == '3' || +this.progressStatus < 0 || this.progressStatus == '') {
                localStorage.setItem('radio_conf', this.radio)
                this.uploadVideo()
            } else {
                if (val === '1') {
                    this.radio = '2'
                } else {
                    this.radio = '1'
                }
                this.$message({
                    message: '请等待当前任务完成',
                    type: 'warning'
                })
            }
        },
        // 判断音视频
        isVideoOrAudioLink(link) {
            // 定义视频文件和音频文件的正则表达式
            const videoExtensions = /\.(mp4|avi|mov|mkv)$/i;
            const audioExtensions = /\.(mp3|wav|ogg|flac)$/i;

            // 使用正则表达式测试链接
            if (videoExtensions.test(link)) {
                return "video";
            } else if (audioExtensions.test(link)) {
                return "audio";
            } else {
                return "video";
            }
        },
        getVideoUrl() {
            fetchVideoApi({ article_id: this.article_id }).then((res) => {
                if (res.data && res.data.code === 200) {
                    if (res.data.data[0].video_url) {
                        this.officialVideoUrl = res.data.data[0].video_url
                    }
                }
            })
        },
        // 获取详情
        fetchVideo() {
            fetchVideoApi({ article_id: this.article_id }).then((res) => {
                if (res.data && res.data.code === 200) {
                    this.typeOneVideo = res.data.data[0].video_url
                    this.typeTwoVideo = res.data.data[0].new_video_tran
                    this.officialVideoUrl = res.data.data[0].new_video_tran ? res.data.data[0].new_video_tran : res.data.data[0].video_url
                    this.vttUrl = res.data.data[0].video_vtt ? res.data.data[0].video_vtt : ''
                    this.translateVttUrl = res.data.data[0].video_vtt_tran ? res.data.data[0].video_vtt_tran : ''
                    this.subtitleTracks[0].src = res.data.data[0].video_vtt;
                    this.taskId = res.data.data[0].task_id
                    // this.subtitlesList = JSON.parse(res.data.data[0].subtitles_list.replace(/'/g, '"'))
                    this.keywordList = res.data.data[0].video_keyword.split(',')
                    this.video_abstract = res.data.data[0].video_abstract
                    this.video_title = res.data.data[0].video_title
                    if (res.data.data[0].video_bright_spot.includes('\n')) {
                        res.data.data[0].video_bright_spot = res.data.data[0].video_bright_spot.replace(/\n/g, '<br/>')
                    }
                    this.videoBrightSpot = res.data.data[0].video_bright_spot.split('；')
                    let child = res.data.data[0].mind_map.children.filter(item => {
                        return item.children[0].name != ''
                    })
                    res.data.data[0].mind_map.children = child
                    this.mindMapList = res.data.data[0].mind_map
                    let list = []

                    this.mindMapList.children.forEach(item => {
                        list.push(item.children[0].name)
                    })
                    let allHeight = 0
                    const allbox = document.getElementById('allbox')
                    list.forEach((item, index) => {
                        // 创建一个盒子
                        let box = document.createElement('div')
                        // 设置盒子的样式
                        box.style.width = '400px'
                        box.innerText = item
                        box.id = 'box' + index
                        // 获取盒子高度
                        // 将盒子添加到父元素
                        allbox.appendChild(box);
                        // 使用 requestAnimationFrame 确保在下一帧渲染前获取高度
                        this.$nextTick(() => {
                            let height = box.offsetHeight;
                            allHeight += height * 2.5;
                            console.log(height);
                        });
                    })
                    this.$nextTick(() => {
                        let echarts = document.querySelector('.mind_mapping')
                        echarts.style.height = allHeight + 'px'
                        console.log(echarts.style.height);
                    });
                    this.fileType = this.officialVideoUrl ? this.isVideoOrAudioLink(this.officialVideoUrl) : 'video'
                    try {
                        this.subtitlesList = JSON.parse(res.data.data[0].subtitles_list.replace(/'/g, '"'))
                    } catch (e) {
                        // 转换出错，抛出异常
                        return false;
                    }
                    this.$nextTick(() => {
                        this.setEcharts()
                    })
                    if (!this.officialVideoUrl) {
                        this.urlTimer = setTimeout(() => {
                            this.fetchVideo()
                        }, 2000)
                    } else {
                        clearTimeout(this.urlTimer)
                        return
                    }
                }

            })
        },
        uploadVideo() {
            againSummaryApi({
                task_id: this.taskId,
                Simplify_detail: this.radio
            }).then((res) => {
                if (res.data.code === 200) {
                    this.newArticleId = this.article_id
                    this.videoUrl = ''
                    // 向本地存入新的视频id 防止刷新丢失
                    localStorage.setItem('article_id', this.article_id)
                    this.queryTask()
                    const h = this.$createElement;
                    this.$notify({
                        title: `${this.radio == 1 ? '精简' : '详细'}提炼中...`,
                        message: h('i', { style: 'color: teal' }, `已选择对音/视频内容进行${this.radio == 1 ? '精简' : '详细'}提炼，提炼结果将在完成后展示，请稍后查看`)
                    });
                }
            })
        },
        // 获取任务进度
        async queryTask() {
            // 调接口
            if (this.isFetching) return;

            this.isFetching = true;

            try {
                const res = await queryTaskApi(this.user_id, this.newArticleId)
                if (res.data.code == 200) {
                    console.log(res);
                    this.progressStatus = res.data.data
                    if (res.data.data == '3') {
                        clearTimeout(this.timer)
                        this.taskSchedule = res.data.data
                        this.article_id = this.newArticleId
                        this.fetchVideo()
                        // 视频处理成功后 移出id
                        const h = this.$createElement;
                        this.$notify({
                            title: '提炼完成',
                            message: h('i', { style: 'color: teal' }, `已切换至${this.radio == 1 ? '精简' : '详细'}模式`)
                        });
                        localStorage.removeItem('article_id')
                    } else {
                        this.taskSchedule = res.data.data
                        if (+res.data.data < 0) {
                            clearTimeout(this.timer)
                            // 视频处理失败后 移出id
                            localStorage.removeItem('article_id')
                            this.$message.error('视频处理失败')
                        } else {
                            this.timer = setTimeout(() => {
                                this.queryTask()
                            }, 20000)
                        }
                    }
                }
            } catch (error) {
                // 处理错误
                console.log(error);
            } finally {
                this.isFetching = false;
            }

        },
        // tab切换 echarts
        tabEcharts() {
            this.tabIndex = 2
            this.setEcharts()
        },
        handlePreview(file) {
            console.log(file)
        },
        handleRemove(file, fileList) {
            console.log(file, fileList)
        },
        beforeRemove(file, fileList) {
            console.log(file, fileList)
        },
        handleExceed(files, fileList) {
            console.log(files, fileList)
        },

        dragControllerDiv() {
            const resize = document.querySelectorAll(".resize");
            const left = document.querySelectorAll(".left");
            const mid = document.querySelectorAll(".mid");
            const box = document.querySelectorAll(".main_right");

            for (let i = 0; i < resize.length; i++) {
                // 鼠标按下事件
                resize[i].onmousedown = function (e) {
                    var mengcen = document.getElementsByClassName("mengcen")[0];
                    mengcen.style.display = "block";
                    //颜色改变提醒
                    resize[i].style.background = "#818181";
                    var startX = e.clientX;
                    resize[i].left = resize[i].offsetLeft;
                    // 鼠标拖动事件
                    document.onmousemove = function (e) {
                        mengcen.style.display = "block";
                        var endX = e.clientX;
                        var moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
                        var maxT = box[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

                        if (moveLen < 32) moveLen = 32; // 左边区域的最小宽度为32px
                        if (moveLen > maxT - 80) moveLen = maxT - 80; //右边区域最小宽度为150px

                        resize[i].style.left = moveLen; // 设置左侧区域的宽度

                        for (let j = 0; j < left.length; j++) {
                            if ($(".center_left").width() == 0) {
                                left[j].style.width = moveLen + "px";
                                mid[j].style.width = box[i].clientWidth - moveLen - 10 + "px";
                            } else {
                                left[j].style.width = moveLen - 280 + "px";
                                mid[j].style.width =
                                    box[i].clientWidth - moveLen - 10 + 285 + "px";
                            }
                        }
                    };
                    // 鼠标松开事件
                    document.onmouseup = function () {
                        //颜色恢复
                        mengcen.style.display = "none";
                        resize[i].style.background = "#11306D";
                        document.onmousemove = null;
                        document.onmouseup = null;
                        resize[i].releaseCapture && resize[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
                    };
                    resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
                    return false;
                };
            }
        },
        shortcut(i) {
            console.log(i)
        },
        stopProcessing(e) {
            let add = {
                ask: "",
                answer: "",
                ask_time: "",
                answer_time: "",
                stat: "",
                id: "",
            };
            this.list.push(add);
            this.list[this.list.length - 2].stat = 0;
            this.list[this.list.length - 2].answer_time = this.currentTime();
            this.list[this.list.length - 2].numbers = e;
            this.limit = true;
            // this.numbers = []
            this.scrollBottm();
            this.list[this.list.length - 2].stop_show = false;
            this.showBlinkCursor = false;
        },
        // 滚动条到底部
        scrollBottm(e) {
            // 如果id为div的元素滚动条位于底部
            // msg-box 是你要滚动的div的id
            if (e) {
                if (this.isScrollbarAtBottom()) {
                    setTimeout(function () {
                        const el = window.document.getElementById("div")
                        if (el.scrollHeight) {
                            el.scrollTop = el.scrollHeight + 100
                        }
                    }, 50);
                }
            } else {
                setTimeout(function () {
                    const el = window.document.getElementById("div")
                    if (el.scrollHeight) {
                        el.scrollTop = el.scrollHeight + 100
                    }
                }, 50)
            }

        },
        stopask() {
            this.returnMessageAjax.abort()
            this.stopProcessing(null)
        },
        isScrollbarAtBottom() {
            const element = window.document.getElementById("div");
            var scrollHeight = element.scrollHeight;
            var clientHeight = element.clientHeight;
            var scrollTop = element.scrollTop;
            return scrollTop === scrollHeight - clientHeight;
        },

        currentTime() {
            var date = new Date();
            var year = date.getFullYear(); //月份从0~11，所以加一
            // let month = date.getMonth();
            var dateArr = [
                date.getMonth() + 1,
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
            ];
            //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
            for (var i = 0; i < dateArr.length; i++) {
                if (dateArr[i] >= 1 && dateArr[i] <= 9) {
                    dateArr[i] = "0" + dateArr[i];
                }
            }
            var strDate =
                year +
                "/" +
                dateArr[0] +
                "/" +
                dateArr[1] +
                " " +
                dateArr[2] +
                ":" +
                dateArr[3] +
                ":" +
                dateArr[4];
            //此处可以拿外部的变量接收，也可直接返回  strDate:2022-05-01 13:25:30
            //this.date = strDate;
            return strDate;
        },
        removeparagraph() {
            this.paragraph = ''
            this.annex_text = "";
            this.$nextTick(() => {
                let newMessage = document.getElementById("fixed_issues");
                var message_bottom = newMessage.offsetHeight;
                if (message_bottom == 0) {
                    return
                }
                this.message_sheight = 'calc(100vh - ' + (message_bottom + 315.09) + 'px)'
            })
        },

        default_question_click(item) {
            // console.log(this.returnMessageAjax)
            if (this.list[this.list.length - 1].stop_show == true) {
                return
            }
            this.questionshow = false
            this.newMessage = item
            this.sendMessage()
            this.$nextTick(() => {
                let newMessage = document.getElementById("fixed_issues");
                var message_bottom = newMessage.offsetHeight;
                if (message_bottom == 0) {
                    return
                }
                this.message_sheight = 'calc(100vh - ' + (message_bottom + 315.09) + 'px)'
            })

        },
        sendMessage() {
            if (!this.newMessage) {
                return;
            }
            this.questionshow = false
            // this.message_sheight = 'calc(100vh - 122px)'
            this.$nextTick(() => {
                let newMessage = document.getElementById("fixed_issues");
                var message_bottom = newMessage.offsetHeight;
                if (message_bottom == 0) {
                    return
                }
                this.message_sheight = 'calc(100vh - ' + (message_bottom + 315.09) + 'px)'
            })
            var newMessage = this.newMessage;
            var paragraph = "";
            if (this.paragraph) {
                paragraph = this.paragraph;
            }
            if (this.annex_text) {
                paragraph = this.annex_text;
            }
            console.log(paragraph);
            if (this.annex_text) {
                // this.list[this.list.length - 1].ask_duan = this.annex_img;
                this.list[this.list.length - 1].isimg = true;
            } else {
                this.list[this.list.length - 1].ask_duan = this.paragraph;
                this.list[this.list.length - 1].isimg = false;
            }
            console.log(this.list);
            this.list[this.list.length - 1].ask = this.newMessage;
            this.list[this.list.length - 1].ask_time = this.currentTime();
            this.list[this.list.length - 1].answer = " ";
            this.show_ = false;
            this.limit = false;
            // this.postQuestions(this.post);
            this.showBlinkCursor = true;
            if (window.document.getElementById("div")) {
                this.scrollBottm();
            }
            let that = this;
            that.start_time = 0;
            that.end_time = 0;
            that.end_ = 0;
            this.newMessage = "";
            this.paragraph = "";
            this.annex_text = "";
            // this.annex_img = "";
            this.list[this.list.length - 1].stop_show = true;
            this.returnMessageAjax = $.ajax({
                url: window.location.origin + "/api/video_questions",
                // url: "http://192.168.66.18:8016/api/video_questions",
                // url: "http://chatmedia.newacademic.net/api/video_questions",
                // timeout: 50000, // 请求超时时间
                type: "POST",
                dataType: "json",
                data: JSON.stringify({
                    text: newMessage,
                    article_id: this.article_id,//this.videoId
                }),
                xhrFields: {
                    onprogress: function (e) {

                        let response = e.currentTarget.response;
                        try {
                            if (response.includes('"code":') && response.includes('"msg":')) {
                                // that.quit();
                                that.$message.error(JSON.parse(response).msg);
                            }
                            if (response.includes("[stop:0]")) {
                                let txt_arr = response.split("[stop:0]");
                                response = txt_arr[0] + txt_arr[1];
                            }
                            if (response.includes("[stop:1]")) {
                                let txt_arr = response.split("[stop:1]");
                                response = txt_arr[0] + txt_arr[1];
                            }

                        } catch (error) {
                            console.log(error);
                        }
                        that.splicing_(response);
                        that.time_(that.start_time, "start");
                        that.scrollBottm('div');

                        // returnMessageAjax.abort();
                    },
                    onload: function () {
                    },
                },
                timeout: 1000 * 60 * 2,
                headers: {
                    // 'Content-Type': 'application/json',
                    token: localStorage.getItem('conf_token'),
                    // "Cache-Control": "no-cache", //重点清除接口的缓存
                },
                complete: function (XMLHttpRequest, status) {
                    // return
                    var responseText = XMLHttpRequest.getResponseHeader('Content-Type');
                    if (responseText) {
                        var numbers = responseText.split('page:')[1]
                    }
                    // 删除numbers中的\r\n 
                    if (numbers) {
                        if (numbers.indexOf("\r\n") != -1) {
                            numbers = numbers.replace(/[\r\n]/g, "");
                        }

                        numbers = numbers.split(',');
                    }

                    if (status === "timeout") {
                        alert("请求超时");
                    } else if (status === "parsererror") {
                        that.time_(that.end_time, "end");
                        that.stopProcessing(numbers);
                    }
                },
            });
        },

        splicing_(txt) {
            console.log(txt);
            if (txt) {
                this.list[this.list.length - 1].answer += txt;
                this.list[this.list.length - 1].answer = txt;
                if (txt.includes("[") && this.leftListShow == 0) {
                    let txt_copy = txt;
                    let txt_arr = txt_copy.split("[");

                    txt_arr[txt_arr.length - 1] = txt_arr[txt_arr.length - 1].slice(
                        0,
                        txt_arr[txt_arr.length - 1].length - 1
                    );
                    // return
                    this.list[this.list.length - 1].id = txt_arr[txt_arr.length - 1];
                    if (txt_arr.length > 0) {
                        let splicing_ = "";
                        txt_arr.forEach((item, index) => {
                            if (index < txt_arr.length - 1) {
                                splicing_ =
                                    txt_arr.length > 2 && index < txt_arr.length - 2
                                        ? splicing_ + item + "["
                                        : splicing_ + item;
                            }
                        });
                        // splicing_ = marked(splicing_);
                        this.list[this.list.length - 1].answer = splicing_;
                    }
                } else {
                    // txt = marked(txt);
                    // 给txt中的a标签添加style 颜色蓝色 带下划线 设置打开新页面
                    txt = txt.replace(/<a/g, '<a style="color:#409EFF;text-decoration:underline;" target="_blank"');
                    // 给txt中的img标签添加style 宽度100% 并增加点击放大事件
                    txt = txt.replace(/<img/g, '<img style="width:100%;cursor:zoom-in;" onclick="imgclick(this)"');
                    console.log(txt);
                    this.list[this.list.length - 1].answer = txt;
                }
            }
        },
        time_(time, type) {
            if (time == 0) {
                if (type == "start") {
                    this.start_time = this.currentTime();
                } else {
                    this.end_time = this.currentTime();
                }
            }
        },

        showquestion(sta) {
            this.questionshow = sta
            this.$nextTick(() => {
                let newMessage = document.getElementById("fixed_issues");
                var message_bottom = newMessage.offsetHeight;
                if (message_bottom == 0) {
                    return
                }
                this.message_sheight = 'calc(100vh - ' + (message_bottom + 315.09) + 'px)'
            })
        },
        obtainDetails(add, id) {
            const include = add.source.includes('bilibili')
            const { href } = this.$router.resolve({
                path: '/broadcast',
                query: {
                    add: JSON.stringify(add),
                    id,
                    include
                }
            });
            window.open(href, '_blank');
        },
        // 绘制echarts
        setEcharts() {
            const chartDom = document.querySelector('.mind_mapping');
            var myChart = echarts.init(chartDom);

            const myOption = {
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove'
                },
                toolbox: {
                    show: true,
                    orient: 'vertical',
                    feature: {
                        saveAsImage: {
                            iconStyle: {
                                // color: '#156bd7',
                                borderColor: '#eee'
                            }
                        }
                    }
                },
                legend: {
                    top: '2%',
                    left: '3%',
                    orient: 'vertical',
                    data: [
                        {
                            icon: 'rectangle'
                        }
                    ],
                },
                series: [
                    {
                        type: 'tree',
                        data: [this.mindMapList],
                        top: '0',
                        left: '10%',
                        bottom: '2%',
                        right: '60%',
                        symbolSize: 7,
                        label: {
                            position: 'left',
                            verticalAlign: 'middle',
                            align: 'right',
                            padding: [5, 8, 5, 8],
                            backgroundColor: '#333641',
                            color: '#fff',
                            fontSize: 14,
                            lineHeight: 20,
                            // maxWidth: 300,
                            // overflow: 'breakAll',
                            // formatter: (params) => {
                            //     return params.name.length > 50 ? params.name.slice(0, 50) + '...' : params.name;
                            // },
                        },
                        leaves: {
                            label: {
                                position: 'right',
                                verticalAlign: 'middle',
                                align: 'left',
                                overflow: 'breakAll',
                                width: 400
                            }
                        },
                        lineStyle: {
                            color: '#D4A4EB',
                            curveness: 0.8,
                        },
                        emphasis: {
                            focus: 'descendant'
                        },
                        tooltip: {
                            show: true,
                            // extraCssText: 'width:300px;word-warp:break-all;',
                            formatter: (params) => {
                                const content = '<div style="max-width: 300px; white-space: normal;">' +
                                    params.name
                                '</div>';
                                return content;
                            },
                            textStyle: {
                                width: '200px',
                                overflow: 'breakAll',
                            }

                        },
                        expandAndCollapse: true,
                        animationDuration: 550,
                        animationDurationUpdate: 750
                    },
                ],

            };
            // 获取echarts_box盒子的宽度
            this.$nextTick(() => {
                const echarts_box = document.querySelector('.echarts_box')
                const echarts_box_width = echarts_box.offsetWidth
                // 设置echarts的宽度   
                chartDom.style.width = echarts_box_width > 800 ? echarts_box_width + 'px' : '800px'
                setTimeout(() => {
                    myChart.resize()
                    myChart.setOption(myOption);
                }, 500)

            })

            // myChart.on('click', function (params) {
            //     // 控制台打印数据的名称
            //     console.log(params);
            // });
        },

        chechTime(time) {
            // 将时间字符串拆分为小时、分钟和秒
            const [hours, minutes, seconds] = time.split(':').map(Number);
            // 将小时、分钟和秒转换为总秒数
            const totalSeconds = hours * 3600 + minutes * 60 + seconds;
            if (this.fileType === 'video') {
                this.$refs.videoPlayer.chechTime(totalSeconds)
            } else {
                this.$refs.audioPlayer.chechTime(totalSeconds)
            }
        },
        downFile() {
            if (this.downType === 'txt') {
                this.downFailSignature()
            } else {
                if (this.tabIndex != 1) {
                    this.showBox = true
                } else {
                    this.showBox = false
                }

                this.$nextTick(() => {
                    this.getPdf(this.video_title, 'export')
                })
            }
        },
        getPdf(name, id) {
            const A4_WIDTH = 592.28
            const A4_HEIGHT = 841.89
            let title = name || 'index'
            const box = document.querySelector(`#${id}`)
            // 克隆box节点
            // 获取节点内的item_title节点并将其字体颜色设置为黑色
            const itemTitle = box.querySelectorAll('.item_title')
            const itemContent = box.querySelectorAll('.count_in')

            console.log('box', box);
            itemTitle.forEach(item => {
                item.style.color = '#000'
            })
            itemContent.forEach(item => {
                item.style.color = '#000'
            })
            html2Canvas(box, {
                allowTaint: true,
                taintTest: false,
                useCORS: true,
                dpi: window.devicePixelRatio * 4, // 将分辨率提高到特定的DPI 提高四倍
                scale: 4, // 按比例增加分辨率
                logging: true // 可以长屏分页导出
            }).then(function (canvas) {
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = contentWidth / 592.28 * 841.89
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 595.28
                let imgHeight = 592.28 / contentWidth * contentHeight
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let PDF = new JsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                itemTitle.forEach(item => {
                    item.style.color = '#fff'
                })
                itemContent.forEach(item => {
                    item.style.color = '#fff'
                })
                PDF.save(title + '.pdf')
            })
            this.showBox = false
        },
        downFailSignature() {
            const title = document.querySelector('#title');
            const titleName = title.querySelector('.item_title').innerHTML;
            const titleDetail = title.querySelector('.count_in').innerHTML;

            const abstract = document.querySelector('#abstract');
            // 获取abstract中p标签的innerHTML
            const abstractTitle = abstract.querySelector('.item_title').innerHTML;
            const abstractDetail = abstract.querySelector('.count_in').innerHTML;

            const spot = document.querySelector('#brightSpot');
            const spotTit = spot.querySelector('.item_title').innerHTML;
            const spotDetail = document.querySelectorAll('#spotxt');
            let spotDetailArr = [];
            // 循环spotDetail取innerHTML
            for (let i = 0; i < spotDetail.length; i++) {
                spotDetailArr.push(spotDetail[i].innerHTML);
            }

            console.log(spotTit, spotDetailArr);
            const messageError = ['\n' + titleName + titleDetail, '\n' + abstractTitle, abstractDetail + '\n', spotTit, ...spotDetailArr];
            const blob = new Blob([messageError.join('\n')], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${this.video_title}.txt`;
            document.body.appendChild(link);
            link.click();
        },
    },

}
</script>

<style lang="scss" scoped>
.main_content {
    display: flex;
    height: calc(100vh - 148px);
    overflow: auto;
    background-color: #161719;
    color: #fff;
    padding: 20px 0;
    box-sizing: border-box;

    .main_left {
        width: 280px;
        max-height: calc(100vh - 148px);
        border-right: 2px solid #11306D;
        text-align: center;
        padding: 30px 20px;
        overflow-y: auto;

        ::v-deep .el-textarea {
            --el-input-border: 1px dashed transparent;
            --el-input-focus-border: 1px dashed transparent;
            --el-input-hover-border-color: transparent;
            --el-input-clear-hover-color: transparent;
            --el-input-focus-border-color: transparent;
            --el-input-border-color: transparent;
        }

        ::v-deep .el-textarea__inner {
            padding: 8px 11px;
            height: 60px !important;
            background-color: transparent;
            border: 1px dashed #fff;
            color: #fff;
        }

        ::v-deep .el-textarea__inner:focus {
            box-shadow: 0 0 0 1px #163572 inset;
        }

        ::v-deep .el-button--primary {
            border-color: #fff;
        }
    }

    .right_chat {
        width: 100%;
        max-height: calc(100% - 148px);
        background-color: #161719;
        // box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.34901960784313724);
        position: relative;
        margin-bottom: 30px;
        position: relative !important;

        .chat_top_box {
            border-bottom: 1px solid #c7c7c7;
            padding: 15px;
            font-size: 14px;
            background: #2C2F3B;

            img {
                vertical-align: middle;
            }
        }

        .fixed_issues {
            .fixed_issues_tit {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                padding: 10px 5px;
                cursor: pointer;

                img {
                    vertical-align: middle;
                    width: 25px !important;
                    margin-bottom: 5px;
                }

                span {
                    // vertical-align: sub;
                }
            }

            .default_question {
                background-color: #161719;
                width: 100%;
                box-sizing: border-box;
                // padding: 8px 0;

                .default_question_item {
                    padding: 2px 10px;

                    .question_item {
                        display: inline-block;
                        text-align: left;
                        color: #fff;
                        font-size: 14px;
                        background-color: #1E1D1D;
                        // box-shadow: 1px 1px 9px rgb(0 0 0 / 35%);
                        line-height: 30px;
                        margin: 3px 0;
                        width: 100%;
                        box-sizing: border-box;
                        border-radius: 3px;
                        padding: 0 10px;
                        cursor: pointer;

                        &:hover {
                            background-color: #3C3B3B;
                        }
                    }
                }
            }
        }

        .message_s {
            // padding: 20px;
            // background-color: #B4D2F7;
            // height: calc(100% - 148px);
            // overflow-x: scroll;
            overflow-y: auto;

            .ask_css {
                width: 100%;
                // padding: 20px 20%;
                padding: 20px;
                font-size: 14px;
                border-bottom: 1px solid #c7c7c7;
                display: flex;
                box-sizing: border-box;
                color: #fff;
                background-color: #161719;

                .ask_img {
                    width: 25px;
                    height: 25px;
                    margin-right: 20px;
                }
            }

            .time__ask {
                font-size: 12px;
                color: #fff;
            }

            .answer_css {
                width: 100%;
                // margin: auto;
                // padding: 20px 20% 5px 20%;
                // margin: 10px 0;
                // display: flex; 
                padding: 10px 20px;
                box-sizing: border-box;
                background: #2C2F3B;
                border-bottom: 1px solid #c7c7c7;
                display: flex;

                .ask_img {
                    width: 25px;
                    height: 25px;
                    margin-right: 20px;
                }

                // background: #f2f2f2;
                .answer_css_ {
                    line-height: 1.5;
                    font-size: 14px;
                }

                .time__answer {
                    font-size: 12px;
                    color: #9ea0a5;
                }

                .pointer_s {
                    display: flex;
                    position: absolute;
                    bottom: -20px;
                    right: 0px;
                    font-size: 14px;

                    img {
                        width: 20px;
                        margin: 0 3px;
                        cursor: pointer;
                    }

                    span {
                        margin: 0 4px;
                        cursor: pointer;
                        line-height: initial;
                    }
                }

                .pointer_s_ {
                    display: flex;
                    position: absolute;
                    bottom: -20px;
                    left: 130px;
                    font-size: 14px;

                    img {
                        width: 20px;
                        margin: 0 3px;
                        cursor: pointer;
                    }

                    span {
                        margin: 0 4px;
                        cursor: pointer;
                    }
                }
            }

            .stopask {
                width: 120px;
                padding: 5px 8px;
                border-radius: 3px;
                text-align: center;
                cursor: pointer;
                border: 1px solid #fff;
                font-size: 15px;
                color: #fff;
                margin: auto;
                margin-top: 15px;

                img {
                    vertical-align: bottom;
                }
            }
        }

        .message_bottom {
            // position: absolute;
            bottom: -70px;
            width: 100%;
            min-height: 70px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 10px 0px;
            box-sizing: border-box;

            .paragraphRe {
                width: 95%;
                margin: 5px auto;
                display: flex;
                justify-content: flex-end;

                i {
                    cursor: pointer;
                    font-size: 18px;
                }
            }

            .chat-input {
                // position: absolute;
                width: 100%;
                padding: 0 15px;
                // bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                div {
                    padding: 10px;
                    background-color: #232324;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    border: 1px solid rgb(68, 65, 65);
                    box-shadow: 1px 1px 9px rgb(0 0 0 / 35%);
                }

                img {
                    width: 25px;
                }

                i {
                    font-size: 30px;
                    margin-right: 8px;
                    cursor: pointer;
                    color: #ffffff;
                }


                .input {
                    flex-grow: 1;
                    padding: 8px;
                    border: none;
                    background: transparent;
                    border-radius: 4px;
                    outline: none;
                    color: #fff !important;
                }

            }

            // 默认问题


        }

        .blinkCursor {
            animation: Blink 1s ease-in 0s infinite;
        }

        @keyframes Blink {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
}

.main_right {
    width: calc(100% - 280px);
    min-width: 1000px;
    margin: auto;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    height: calc(100vh - 188px);
    background-color: #161719;
    box-sizing: border-box;
    // background: linear-gradient(179.99999999999994deg,
    //         rgba(212, 225, 251, 1) 0%,
    //         rgba(236, 223, 232, 1) 15%,
    //         rgba(255, 255, 255, 1) 31%);

    .left {
        position: relative;
        width: calc(100% - 360px);
        overflow-y: auto;
        margin-right: 25px;
        /*左侧初始化宽度*/
        background-color: #161719;

        .video_realtive_box {
            display: flex;
        }

        .captions_box {
            width: calc(30% - 8px);
            margin-left: 8px;
            padding: 10px;
            background-color: #24262D;
            border-radius: 5px;
            overflow: hidden;

            .related_live {
                // min-height: 435px;
                // max-height: 600px;
                overflow: auto;

                .captions_title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                        width: 20px;
                        vertical-align: sub;
                    }
                }

                h4 {
                    margin-bottom: 8px;
                }

                .all_list {
                    height: calc(100% - 45px);
                    overflow: auto;
                }

                .subtitleList {
                    width: 100%;
                    height: calc(100% - 25px);
                    padding: 8px 5px;
                    font-size: 14px;
                    border-radius: 5px;
                    display: flex;
                    // overflow-y: auto;

                    .subtitle_time {
                        padding: 0 3px;
                        height: 25px;
                        line-height: 25px;
                        background: #B4D2F7;
                        margin-right: 10px;
                        color: #156BD7;
                        border-radius: 3px;
                    }

                    .subtitle_content {
                        // width: ;
                        line-height: 25px;
                        width: calc(100% - 72px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &:hover {
                        background: #F2F2F2;
                        cursor: pointer;
                        color: #156BD7;
                    }
                }
            }
        }

        .video_box {
            display: flex;
            width: calc(70% - 8px);
            border-radius: 5px;
            overflow: hidden;
            margin-right: 8px;

            .audio_box {
                height: 208px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .audio_player {
                margin: 75px 20px;
                width: 50% !important;
            }

            .l_viedo {
                width: 100%;
                max-height: 700px;
            }

        }

        .bottom_main_info {
            padding: 10px 0;
            margin-top: 10px;

            .top_fun_two {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                background-color: #373333;
                width: 100%;
                border: 1px solid #979494;
                border-radius: 5px;
            }

            .right_download_fun {
                // background-color: #373333;
                padding: 10px;
                border-radius: 5px;
                font-size: 15px;
                line-height: 25px;
                text-align: right;

                .down_fun {
                    margin-right: 8px;
                    cursor: pointer;

                    &:hover {
                        border-bottom: 1px solid #fff;
                    }
                }

                ::v-deep .el-radio {
                    color: #fff;
                }

                ::v-deep .el-radio__input.is-checked .el-radio__inner {
                    border-color: #156BD7;
                    background-color: #156BD7;
                }

                ::v-deep .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                }

                ::v-deep .el-input__icon {
                    line-height: 30px;
                }
            }

            .main_top_nav {

                padding: 10px;
                display: flex;
                flex-wrap: wrap;
                // margin-top: 10px;

                span {
                    margin: 5px 10px;
                    font-size: 15px;
                    width: 85px;
                    cursor: pointer;
                    // margin-bottom: 10px;
                    line-height: 25px;
                    padding: 5px;
                    text-align: center;
                    border-radius: 3px;
                }

                .active {
                    font-size: 16px;
                    font-weight: 700;
                    color: #fff;
                    background-color: #191818;
                }
            }

            .bottom_detail {
                margin: 20px 0;
                background-color: #242528;
                border-radius: 5px;
                padding: 15px;
                font-size: 14px;

                .same_box {
                    min-height: 435px;
                    max-height: 600px;
                    overflow-y: auto;
                    padding: 15px;
                }

                .item_title {
                    font-weight: 700;
                    // color: red;
                }

                .keyword_tip {
                    padding: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    color: #fff;

                    span {
                        border: 1px solid #446ce6;
                        padding: 3px 10px;
                        margin-bottom: 20px;
                        margin-right: 8px;
                        background-color: #fff;
                        border-radius: 15px;
                    }

                    span:nth-child(4n+1),
                    span:nth-child(4n+2) {
                        color: #446ce6;
                    }

                    span:nth-child(4n+3),
                    span:nth-child(4n+4) {
                        color: #5e8819;
                        border-color: #5e8819;
                    }
                }

                .echarts_outBox {
                    overflow: auto;
                }

                .echarts_box {
                    width: 100%;
                }


            }

            .introduce {
                color: #fff;
                line-height: 25px;
                margin-bottom: 15px;
                display: flex;
                align-items: flex-start;

                .count_in {
                    width: 90%;

                    p {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .mid {
        width: 380px;
        height: calc(100vh - 188px);
        /*右侧初始化宽度*/
        background: #161719;
        position: relative;
        border: 1px solid #979494;
        border-radius: 5px;
        overflow: hidden;

        /* box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11); */
        // position: relative;
        .mid_top {
            width: 100%;
            height: 36px;
            background: #fff;
            border-bottom: 1px solid #c7c7c7;
            display: flex;
            padding: 0 10px;
            align-items: center;
            font-weight: 600;

            img {
                width: 25px;
            }
        }

        .top_fun_list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 8px;

            // border-bottom: 1px solid #e0e0e0;
            img {
                vertical-align: sub;
            }
        }
    }
}

.mini_video {
    position: absolute;
    bottom: 100px;
    right: 20px;
    width: 300px;

    video {
        width: 100%;
        height: 100%;
    }
}

.main_left {
    .top_title {
        margin-bottom: 15px;

        h3 {
            margin-bottom: 10px;
        }

        div {
            font-size: 14px;
            line-height: 25px;
        }
    }

    .tip_btn {
        margin-bottom: 15px;
    }

    ::v-deep .el-button {
        margin: 15px 0;
        background-color: transparent;
        --el-button-border-color: #fff;
        --el-button-active-bg-color: #fff;
        --el-button-hover-border-color: #fff;
    }

    ::v-deep .el-input {
        --el-input-focus-border: #163572;
        --el-input-focus-border-color: #163572;
        box-shadow: none;
    }

    ::v-deep .el-select {
        --el-select-input-focus-border-color: #163572;
    }

    .slider_box {
        text-align: left;
        font-size: 14px;
        margin: 30px 0;

        ::v-deep .el-slider__bar {
            background-color: #163572;
        }

        ::v-deep .el-slider {
            margin-top: 8px;
            --el-slider-main-bg-color: #163572;
        }

        ::v-deep .el-slider__button {
            width: 15px;
            height: 15px;
            border: 2px solid #163572;
            background-color: #163572;
        }
    }

    .main_btn {
        width: 200px;
        height: 40px;
        background: #163572;
        color: #fff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        margin: 20px auto;
    }
}

.upload_progress {
    ::v-deep .el-progress-bar {
        width: 470px !important;
    }
}

.text_description {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 25px;
}
</style>