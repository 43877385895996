import request from '@/utils/chat_reques';
import qs from 'qs';


/**
 * 个人账号登录
 * @param {*} query 
 * @returns 
 */
export function personalLoginApi(query) {
    return request({
        url: 'personlogin/',
        method: 'post',
        data: qs.stringify(query)
    })
}

/**
 * IP登录
 * @returns 
 */
export function ipLoginApi() {
    return request({
        url: 'iplogin/',
        method: 'get',
        params: {
            type:1
        }
    })
}

/**
 * 个人账号注册
 * @param {*} query 
 * @returns 
 */
export function registerApi(query) {
    return request({
        url: 'register/',
        method: 'post',
        data: qs.stringify(query) 
    })
}

/**
 * 发送短信验证码
 * @param {*} phone 
 * @returns 
 */
export function sendMessageApi(data) {
    return request({
        url: 'sendmsg/',
        method: 'post',
        data
    })
}