<template>
    <div>
        <!-- 音频播放器 -->
        <audio id="audioPlayer" controls>
            <source :src="audioUrl" type="audio/mpeg">
            <!-- <track v-for="(track, index) in subtitleTracks" :key="index" :src="track.src" kind="subtitles"
                :srclang="track.srclang" :label="track.label"> -->
        </audio>

    </div>
</template>
  
<script>
import VueAudioPlayer from 'vue-audio-player';

export default {
    components: {
        VueAudioPlayer
    },
    props: {
        audioUrl: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
          
        };

    },
    methods: {
        handleTimeUpdate(currentTime) {
            // 处理播放时间更新事件
            console.log('当前播放时间：', currentTime);
            // 可以在这里更新音频文本的显示
        },
        chechTime(time) {
            console.log('跳转到：', time, '秒');
            let audio = document.getElementById('audioPlayer');
            audio.currentTime = time;
        },
    },
};
</script>
  
<style>
/* 可以在这里添加样式以自定义播放器外观 */
audio{
    width: 100%;
}
</style>
  